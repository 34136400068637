
import React, { useEffect, useState } from "react";
import { Box, Typography } from '@mui/material';
import { PrimaryButton, SecondaryButton } from '../components/Buttons/Buttons';
import { useLocation } from 'react-router-dom';
import { firePurchaseEventWithItems, initializeGTM } from "../utilities/GoogleAnalytics";

function SucessPageComp(props) {
    const liveMode = process.env.NODE_ENV === "production" || false
    const stripeAPI = process.env.REACT_APP_BACKEND_URL
    const [session, setSession] = useState({});
    const location = useLocation();
    const sessionId = location.search.replace('?session_id=', '');
    const checkoutHandler = () => {
        // console.log({ session })
        window.open(`${window.location.origin}`, "_self")

    }

    const viewInvoiceHandler = () => {
        window.open(`${session?.receipt_url || window.location.origin}`, "_blank")
    }

    // Check if session object is not empty
    if (Object.keys(session).length > 0 && session.line_items && Object.keys(session.line_items).length > 0) {
        const ecommerce_data = {
            transaction_id: session.charge_id,
            value: session.amount_received,
            currency: session.currency,
            items: session.line_items
        }

        firePurchaseEventWithItems(ecommerce_data);
    }

    useEffect(() => {

        initializeGTM();

        async function fetchSession() {
            setSession(
                await fetch(`${stripeAPI}/api/v1/payments/summary?livemode=${liveMode}&session_id=${sessionId}`).then((res) =>
                    res.json()
                )
            );
        }
        fetchSession();
    }, [sessionId]);

    return (
        <React.Fragment>
            <Box sx={{
                position: 'absolute',
                top: '0',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "40px",
                transition: 'opacity 1s',
                opacity: '1',
                zIndex: '1400',
                backgroundColor: "#262626",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                fontWeight: 600,
            }}>
                <Box
                    component="img"
                    src={"/assets/images/LogoSuccesIcon.svg"}
                    sx={{
                        fontSize: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                ></Box>
                {session.invoice?.currency &&
                    <Typography
                        sx={{
                            color: 'white',
                            fontSize: "2rem"
                        }}
                    >
                        {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: session.invoice?.currency,
                        }).format(session.invoice?.total * 1 || 0)}</Typography>
                }

                <Typography
                    sx={{
                        color: 'white',
                        fontSize: "22px"
                    }}
                >Payment Received!</Typography>

                <Typography
                    sx={{
                        color: 'white',
                        fontSize: "14px",
                        maxWidth: "450px",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                    }}
                >Thank you for your transaction with Throne Tools Corporation. Your order is now confirmed.</Typography>
                <Box

                    sx={{
                        width: "100%",
                        gap: "10px",
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxWidth: "350px"
                    }}
                >
                    <PrimaryButton
                        sx={{
                            backgroundColor: "#32BA7C",
                            border: "0",
                        }}
                        onClick={checkoutHandler}
                    >return home</PrimaryButton>
                    <SecondaryButton
                        sx={{
                            backgroundColor: "#474848",
                            border: "0"
                        }}
                        onClick={viewInvoiceHandler}
                    >Show Invoice</SecondaryButton>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default SucessPageComp;


