import { Vector3, Raycaster, Group } from 'three';
import { CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';

export default class HotSpots {
    constructor(camera, group) {
        this.group = group

        this.camera = camera
        this.hotSpotAnotations = []
        this.hotSpots = []

        this.index = 0
        this.raycaster = new Raycaster();
        this.tempGrp = new Group()

        this.tempVector = new Vector3()
        this.tempWorldPosition = new Vector3()
        // this.arrowHelper = new ArrowHelper();
        // this.tempGrp.add(this.axesHelper);
        // this.group.add(this.arrowHelper)
        this.cameraNormal = new Vector3()

        // this.init(hotSpotsData)
        this.view = new Vector3();
        this.target = new Vector3();
        this.normalWorld = new Vector3();

    }

    init(hotSpots) {

        if (this.hotSpots.length > 0) {
            // console.log(this.hotSpots)
            this.hotSpots.forEach(spot => {
                this.group.remove(spot)
            })

            this.hotSpotAnotations = []
            this.hotSpots = []

        }

        if (hotSpots) {
            hotSpots.forEach((spot, index) => {
                const div = this.creatLable(spot.annotation, index)
                this.hotSpotAnotations.push(div)

                const lable = new CSS2DObject(div);
                // console.log(earthLabel)
                lable.visible = false
                lable.position.set(...spot.position);
                lable.normal = spot?.normal ? new Vector3(...spot.normal) : new Vector3(0, 0, 1)
                this.group.add(lable);
                this.hotSpots.push(lable)
            })

        }

        this.animate()
    }

    toggleVisible(value) {
        this.hotSpots.forEach(spot => spot.visible = value)
    }

    disableHotspot(index) {
        const spot = this.hotSpotAnotations[index].querySelector(".Hotspot")
        const anotation = this.hotSpotAnotations[index].querySelector(".lable-content")

        spot.classList.add("hide");
        anotation.classList.add("hide");
    }

    enableHotspot(index) {
        const spot = this.hotSpotAnotations[index].querySelector(".Hotspot")
        const anotation = this.hotSpotAnotations[index].querySelector(".lable-content")

        spot.classList.remove("hide");
        anotation.classList.remove("hide");
    }

/*
    toggleHotSpot(index) {
        this.hotSpotAnotations.forEach(el => {
            const annotation = el.querySelector(".lable-content");
            const annotationIndex = el.dataset.index;
            const hotSpot = el.querySelector(".Hotspot");
            if (Number(annotationIndex) === index) {
                if (annotation.classList.contains("show")) {
                    annotation.classList.remove("show");
                    hotSpot.classList.remove("open");
                } else {
                    annotation.classList.add("show");
                    hotSpot.classList.add("open");
                }
            } else {
                annotation.classList.remove("show");
                hotSpot.classList.remove("open");
            }
        });
    }
*/

    toggleHotSpot(index) {
        this.hotSpotAnotations.forEach(el => {
            const anotation = el.querySelector(".lable-content")
            const anotationIndex = el.dataset.index
            const hotSpot = el.querySelector(".Hotspot");
            if (Number(anotationIndex) === index) {
                if (anotation.style.display === "none") {
                    anotation.style.display = "block"
                    el.querySelector(".Hotspot").classList.add("hide");
                    hotSpot.classList.remove("open");
                } else {
                    anotation.style.display = "none"
                    el.querySelector(".Hotspot").classList.remove("hide");
                    hotSpot.classList.add("open");
                }
            } else {
                anotation.style.display = "none"
                el.querySelector(".Hotspot").classList.remove("hide");
                    hotSpot.classList.add("open");
            }
        })
    }

    creatLable(content, index) {
        const lable = document.createElement('div')
        const lableContainer = document.createElement('div')
        lableContainer.className = 'label';

        const hotSport = document.createElement('div')
        hotSport.className = 'Hotspot';
        lable.dataset.index = index

        const lableContent = document.createElement('div')
        lableContent.className = 'lable-content';
        lableContent.textContent = content


        lableContent.style.display = "none"
        lable.appendChild(lableContainer)
        lableContainer.appendChild(hotSport)
        lableContainer.appendChild(lableContent)

        hotSport.addEventListener('pointerdown', () => {
            this.toggleHotSpot(index)
            this.update()

            // console.log(this.hotSpots[index])

            // if (lableContent.style.display === "none") {
            //     lableContent.style.display = "block"
            // } else {
            //     lableContent.style.display = "none"
            // }

        })

        return lable
    }

    update() {
        this.camera.getWorldDirection(this.cameraNormal)
        this.hotSpots.forEach((hotspot, index) => {
            const dot = hotspot.normal.dot(this.cameraNormal)
            if (dot > -0.5) {
                this.disableHotspot(index)
            } else {
                this.enableHotspot(index)
            }
        })

    }


    animate(time) {
        this.animationFrame = requestAnimationFrame(this.animate.bind(this));
        this.update(time)
        // this.arScene.translateX(0.02)
    }

    stop() {
        cancelAnimationFrame(this.animationFrame)

    }

}
