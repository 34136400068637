import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import AppProvider from "./AppContext";
import SucessPageComp from "./pages/SucessPageComp";
import FailPaymentPageComp from "./pages/FailPaymentPageComp";



import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermAndConditions from "./pages/TermAndConditions";
import TermOfUse from "./pages/TermOfUse";

const RouterComp = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-and-conditions" element={<TermAndConditions />} />
                <Route path="/term-of-use" element={<TermOfUse />} />
                <Route path="/fail-payment" element={<FailPaymentPageComp />} />
                <Route path="/success" element={<SucessPageComp />} />
                <Route path="/products/:name" element={<AppProvider />} />
                <Route path="*" element={<AppProvider />} />
            </Routes>
        </BrowserRouter>
    );
}

export default RouterComp;

