import React, { useContext, useState, useEffect } from "react";
import { Box, Typography, Button, FormControl, InputLabel, Select, MenuItem, Chip, OutlinedInput, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { CheckoutContext } from "../CheckoutModal";

function OtherProducts() {
    const { allProducts, checkoutProducts, addProductHandler, setPreviewProduct } = useContext(CheckoutContext);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [filters, setFilters] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const filterOptions = ['all', ...new Set([
        ...allProducts.map(product => product.category_name),
        ...allProducts.map(product => product.vendor_name)
    ])];

    useEffect(() => {
        const otherProducts = allProducts.filter(product => !checkoutProducts.find(cp => cp.name === product.name));
        
        const filtered = otherProducts.filter(product => 
            (filters.length === 0 || filters.includes('all') || filters.includes(product.category_name) || filters.includes(product.vendor_name)) &&
            product.name.toLowerCase().includes(searchQuery.toLowerCase())
        );

        setFilteredProducts(filtered);
    }, [allProducts, checkoutProducts, filters, searchQuery]);

    const handleFilterChange = (event) => {
        const {
            target: { value },
        } = event;
        setFilters(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleDeleteChip = (chipToDelete) => (event) => {
        event.preventDefault();
        event.stopPropagation();
        setFilters((chips) => chips.filter((chip) => chip !== chipToDelete));
    };

    return (
        <Box sx={{ width: {xs: "90%", sm: "100%"}, maxWidth: "592px", padding: "0 16px" }}>
            <Box sx={{ 
                display: "flex", 
                flexDirection: "column",
                gap: "16px", 
                mb: "16px", 
                alignItems: "center", 
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "16px",
                    width: "100%",
                    justifyContent: "center"
                }}>
                    <FormControl sx={{ flex: 1, maxWidth: "45%" }}>
                        <Select
                            multiple
                            value={filters}
                            displayEmpty
                            onChange={handleFilterChange}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                                return <em>Filter Categories</em>;
                            }}
                            sx={{
                                background: "#33393E",
                                borderRadius: "8px",
                                maxHeight: "52px",
                                color: "white",
                                "& .MuiOutlinedInput-notchedOutline": { 
                                    borderColor: "#95783B",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#95783B",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#95783B",
                                },
                                zIndex: 3000,
                            }}
                            MenuProps={{
                                sx: {
                                    zIndex: 3000,
                                },
                                PaperProps: {
                                    sx: {
                                        zIndex: 3000,
                                        background: "#33393E",
                                        color: "white",
                                    }
                                }
                            }}
                        >
                            {filterOptions.map((option) => (
                                <MenuItem key={option} value={option} sx={{ backgroundColor: "#33393E", color: "white" }}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        sx={{ flex: 1, maxHeight: "52px", maxWidth: "45%" }}
                        placeholder="Search products..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                            sx: {
                                background: "#33393E",
                                color: "white",
                                borderRadius: "8px",
                                "& fieldset": {
                                    borderColor: "#95783B",
                                },
                                "&:hover fieldset": {
                                    borderColor: "#95783B",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "#95783B",
                                },
                                "& .MuiOutlinedInput-notchedOutline": { 
                                    borderColor: "#95783B",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#95783B",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#95783B",
                                },
                            },
                        }}
                    />
                </Box>
                {filters.length > 0 && (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, justifyContent: 'center' }}>
                        {filters.map((value) => (
                            <Chip
                                key={value}
                                label={value}
                                onDelete={handleDeleteChip(value)}
                                deleteIcon={
                                    <CloseIcon 
                                        onMouseDown={(event) => event.stopPropagation()}
                                    />
                                }
                                sx={{
                                    backgroundColor: "#95783B",
                                    color: "white",
                                    '& .MuiChip-deleteIcon': {
                                        color: 'white',
                                    },
                                }}
                            />
                        ))}
                    </Box>
                )}
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "16px",
                width: "100%",
            }}>
                {filteredProducts.map((product, index) => (
                    product && (
                        <AddCard
                            key={index}
                            product={product}
                            onAdd={() => addProductHandler(product)}
                            onClick={() => setPreviewProduct(product)}
                        />
                    )
                ))}
            </Box>
        </Box>
    );
}

export default OtherProducts;

const AddCard = ({ product, onAdd, onClick }) => {
    const { setPreviewProduct } = useContext(CheckoutContext);

    const handleCardClick = (e) => {
        if (!e.target.closest('.add-product-button')) {
            setPreviewProduct(product);
        }
    };

    const handleAddProduct = (product, e) => {
        e.stopPropagation();
        onAdd(product);
    };

    return (
        <Box
            sx={{
                maxWidth: {xs: "90%", sm: "592px"},
                width: "100%",
                border: "1px solid rgba(255, 255, 255, 0.46)",
                borderRadius: "8px",
                padding: {xs: "12px", sm: "16px"},
                background: "#141313",
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                cursor: "pointer",
            }}
            onClick={(e) => handleCardClick(e)}
        >
            {/* Top Row: Image, Name, and Price */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: {xs: "8px", sm: "16px"}, flexGrow: 1, maxWidth: "calc(100% - 100px)" }}>
                    <Box
                        component="img"
                        src={product.default_image}
                        alt={product.name}
                        sx={{
                            width: "64px",
                            height: "64px",
                            objectFit: "cover",
                            borderRadius: "4px",
                            flexShrink: 0,
                            cursor: "pointer",
                        }}
                        onClick={() => setPreviewProduct(product)}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            gap: "4px",
                            flexShrink: 0,
                            maxWidth: "calc(100% - 150px)",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                            fontWeight: "bold",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                        }}
                        >
                            {product.name}
                        </Typography>
                        <Typography variant="h6" sx={{ fontWeight: "400", fontSize: "14px" }}>
                        {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: product.price_details.currency,
                            }).format(product.price_details.retail_price)}
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ width: "100px", textAlign: "left", flexShrink: 0 }}>
                <Button
                        className="add-product-button"
                        onClick={(e) => handleAddProduct(product, e)}
                        size="small"
                        sx={{
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "500",
                            minWidth: "80px",
                            "&:hover": {
                                background: "transparent",
                            }
                        }}
                        startIcon={<AddIcon />}
                        variant="text"
                    >
                        Add
                    </Button>
                </Box>
            </Box>

            {/* Bottom Row: Description and Add Button */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                <Box 
                    dangerouslySetInnerHTML={{ __html: product.description }}
                    sx={{
                        flexGrow: 1,
                        marginRight: "16px",
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        fontSize: "0.875rem",
                        lineHeight: 1.5,
                        maxWidth: "100%",
                    }}
                />
            </Box>
        </Box>
    );
}