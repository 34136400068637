'use client';
import { useState } from 'react';
import { Box, Modal, Backdrop, Fade, IconButton, Icon } from '@mui/material';
import TabFooter from "../TabFooter";
import CloseIcon from '@mui/icons-material/Close';
import { PlayArrow } from '@mui/icons-material';
import zIndex from '@mui/material/styles/zIndex';

const Video = ({ mediaData }) => {
    const [open, setOpen] = useState(false);
    const videoData = mediaData?.videos?.[0]; // get the first video from the array for now

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                position: "relative",
                padding: { xs: "10px 0", sm: "10px 0" }
            }}
        >
            <Box
                sx={{
                    position: "relative",
                    width: "200px",
                    height: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
            >
                <PlayArrow
                    sx={{
                        position: "absolute",
                        color: "white",
                        fontSize: "50px",
                        cursor: "pointer",
                        zIndex: 10,
                    }}
                    onClick={handleOpen}
                />
                <video
                    onClick={handleOpen}
                    muted
                    autoPlay={false}
                    loop={false}
                    src={videoData?.public_url}
                    style={{
                        width: "200px",
                        height: "auto",
                        maxHeight: "80vh",
                        borderRadius: "10px",
                    }}
                />
            </Box>

            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: '90%', sm: '80%', md: '70%' },
                            // linear-gradient(180deg, #33393E 0%, #181B1D 100%)
                            background: 'linear-gradient(180deg, #33393E 0%, #181B1D 100%)',
                            boxShadow: 24,
                            p: 4,
                            outline: 0,
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <IconButton
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                color: 'white',
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <VideoPlayer src={videoData?.public_url} />
                    </Box>
                </Fade>
            </Modal>

            <Box
                sx={{
                    marginTop: "40px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "20px"
                }}
            >
            </Box>

            <TabFooter />
            <Box />
        </Box>
    );
}

const VideoPlayer =(props) => {
    return (
        <video
            controls
            muted
            autoPlay
            loop
            playsInline
            src={props.src}
            style={{
                width: "100%",
                height: "auto",
                maxHeight: "80vh",
                backgroundColor: "black",
                borderRadius: "10px",
            }}
        />
    );
}

export default Video;
