import TagManager from "react-gtm-module";

const pushToDataLayer = (data) => {
    console.log(data);
    if (window.location.hostname === "localhost") return null
    window.dataLayer.push(data);
    return null; // Since this is a utility component, it doesn't render anything
};

// get affiliate_id from query and decode if exist and then set a cookie for 30 days
const setAffiliateCookie = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const affiliate_id = urlParams.get('affiliate_id');
    if (affiliate_id) {
        const d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000)); // One month in seconds. Feel free to change the time
        const expires = "expires=" + d.toUTCString();
        document.cookie = `affiliate_id=${affiliate_id};${expires};path=/`;
    }
    return null;
}

// get affiliate_id from cookie
const getAffiliateCookie = () => {
    const name = "affiliate_id=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

// set affiliate_id to dataLayer
const setAffiliateDataLayer = () => {
    const affiliate_id = getAffiliateCookie();
    if (affiliate_id) {
        pushToDataLayer({ 'affiliate_id': affiliate_id });
    }
    return null;
}

//initialize GA4
const initializeGTM = () => {
    // pre-checks
    setAffiliateCookie();

    const tagManagerArgs = {
        gtmId: process.env.NODE_ENV === 'development' ?
            process.env.REACT_APP_GTAG_ID_DEV :
            process.env.REACT_APP_GTAG_ID_PROD
    }

    TagManager.initialize(tagManagerArgs)
    return null;
}


const firePurchaseEventWithItems = (ecommerce_data) => {
    console.log(ecommerce_data);
    pushToDataLayer({
        event: 'purchase',
        ecommerce: ecommerce_data
    });
}

export { pushToDataLayer, setAffiliateCookie, getAffiliateCookie, setAffiliateDataLayer, initializeGTM, firePurchaseEventWithItems };