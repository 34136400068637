import { Box, Typography } from '@mui/material';

import { AppContext } from '../../AppContext';
import { useContext } from 'react';

import { priceToUsdString } from '../../utilities/priceToUsd';

function ProductTitle() {

    const { productData } = useContext(AppContext)
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: { xs: "center", md: "flex-start" },
                justifyContent: { xs: "space-around", md: "flex-start" },
                flexDirection: "row",
                color: "white",
                gap:  { xs: "10px", md: `calc(10px + 8vw)` },
                padding: "0px 20px",
                marginBottom: "30px",
                borderRadius: "20px",
                width: "100%",
            }}
        >
            <Typography
                sx={{
                    fontSize: { xs: "1.2rem", md: "20px" },
                    fontWeight: "700",
                    alignItems: { xs: "center", md: "flex-start" },
                }}

            >{productData?.displayedProduct?.mainProduct?.name}</Typography>
            <Typography
                sx={{
                    fontSize: { xs: "1.2rem", md: "20px" },
                    fontWeight: "700",
                    color: "#95783B"
                }}
            >{priceToUsdString(productData?.displayedProduct?.mainProduct?.price_details?.retail_price)}</Typography>
        </Box>
    );
}

export default ProductTitle;



