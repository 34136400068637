import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import RouterComp from './RouterComp';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 1030,
          md: 1100,
          lg: 1200,
          xl: 1536,
        },
      },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ThemeProvider theme={theme}>
        <RouterComp />
    </ThemeProvider>

);

