import React, { useContext } from "react";
import { Box, IconButton, Typography, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { CheckoutContext } from "../CheckoutModal";

function CheckoutProducts() {
    const { checkoutProducts, changeQuantityHandler } = useContext(CheckoutContext);

    

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "16px",
                width: "100%",
                maxWidth: "592px",
            }}
        >
            {checkoutProducts.map((product, index) => (
                product && (
                    <ProductCard
                        key={index}
                        mode="checkout"
                        product={product}
                        setQuantity={(value) => changeQuantityHandler(value, index)}
                    />
                )
            ))}
        </Box>
    );
}

export default CheckoutProducts;

const ProductCard = ({ mode, product, setQuantity }) => {
    const { setPreviewProduct } = useContext(CheckoutContext);
    
    const handleCardClick = (e) => {
        if (!e.target.closest('.quantity-controls')) {
            setPreviewProduct(product);
        }
    };

    const handleQuantityChange = (change, e) => {
        e.stopPropagation();
        setQuantity(change);
    };

    return (
        <Box
            onClick={handleCardClick}
            sx={{
                maxWidth: {xs: "90%", sm: "592px"},
                width: "100%",
                border: "1px solid rgba(255, 255, 255, 0.46)",
                borderRadius: "8px",
                padding: {xs: "12px", sm: "16px"},
                background: mode === "add" ? "#141313" : "transparent",
                display: "flex",
                flexDirection: "column",
                gap: "0px",
                cursor: "pointer",
            }}
        >
            {/* Top Row: Image, Name, and Price */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: {xs: "8px", sm: "16px"}, flexGrow: 1, maxWidth: "calc(100% - 125px)" }}>
                    <Box
                        component="img"
                        src={product.default_image}
                        alt={product.name}
                        sx={{
                            width: "64px",
                            height: "64px",
                            objectFit: "cover",
                            borderRadius: "4px",
                            flexShrink: 0,
                        }}
                        onClick={() => setPreviewProduct(product)}
                    />
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            fontWeight: "700",
                            fontSize: "18px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "calc(100% - 150px)",
                        }}
                    >
                        {product.name}
                    </Typography>
                </Box>
                <Box sx={{ width: "125px", display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "4px", flexShrink: 0 }}>
                    <Typography variant="h6" sx={{ fontWeight: "400", fontSize: "20px" }}>
                        {Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: product.price_details.currency,
                        }).format(product.price_details.retail_price)}
                    </Typography>
                </Box>
            </Box>

            {/* Bottom Row: Description and Quantity */}
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", gap: "8px" }}>
                <Box 
                    dangerouslySetInnerHTML={{ __html: product.description }}
                    sx={{
                        flexGrow: 1,
                        marginRight: "16px",
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                        fontSize: "0.875rem",
                        lineHeight: 1.5,
                        maxWidth: "calc(100% - 125px)",
                    }}
                />
                <Box sx={{ width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "4px", flexShrink: 0 }}>
                    <Box className="quantity-controls" sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "28px" }}>
                        <CustomButton onClick={(e) => handleQuantityChange(-1, e)} size="small">
                            <RemoveIcon fontSize="small" />  
                        </CustomButton>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "#fff",
                                borderRadius: "10px",
                                padding: "2px",
                                minHeight: "25px",
                                maxHeight: "25px",
                                minWidth: "25px",
                                maxWidth: "25px",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: "700",
                                    fontSize: "12px",
                                    textAlign: "center",
                                    color: "black",
                                }}
                            >
                                {product.quantity || 1}
                            </Typography>
                        </Box>  
                        <CustomButton onClick={(e) => handleQuantityChange(1, e)} size="small">
                            <AddIcon fontSize="small" />
                        </CustomButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

const CustomButton = styled(IconButton)((props) => ({
    background: "#fff", 
    fontSize: "12px",
    color: "black",
    borderRadius: "50%",
    padding: "8px",
    maxWidth: "16px",
    minWidth: "16px",
    minHeight: "16px",
    maxHeight: "16px",
}), {
    '&::before': {
        content: '""',
        position: 'absolute',
        top: '-6px',
        left: '-6px',
        right: '-6px',
        bottom: '-6px',
        background: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '50%',
        zIndex: -1,
    },
});