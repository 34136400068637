import { Box, Typography } from '@mui/material';

const SearchResults = ({ isLoadingSearchResults, searchResults, handleSearchResultClick }) => {
  return (
    <Box
      id="search-results"
      sx={{
        position: "absolute",
        top: "100%",
        left: { xs: "-5", md: "0" },
        width: { xs: "300px", md: "100%", lg: "100%" },
        background: "linear-gradient(180deg, #33393E 0%, #181B1D 100%)",
        color: "#fff",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        maxHeight: "200px",
        overflowY: "auto",
        scrollbarWidth: "thin",
        scrollbarColor: "#33393E #262626",
        "&::-webkit-scrollbar": {
          width: "5px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#262626",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#33393E",
        },
      }}
    >
      {isLoadingSearchResults && searchResults.length === 0 && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Typography variant="body1">Loading...</Typography>
        </Box>
      )}
      {searchResults.length === 0 && !isLoadingSearchResults && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <Typography variant="body1">No results found</Typography>
        </Box>
      )}
      {searchResults.map((result, index) => (
        <Box
          key={index}
          onClick={() => handleSearchResultClick(result)}
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            padding: "10px",
            "&:hover": {
              backgroundColor: "#33393E",
            },
            "&:not(:last-child)": {
              borderBottom: "1px solid #ddd",
            },
          }}
        >
          <img src={result.default_image} alt={result.name} style={{ width: "42px", height: "42px", marginRight: "10px" }} />
          <Typography 
            variant="body1"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {result.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default SearchResults;
