
import React, { useContext, useState } from "react";
import { Box, IconButton, Badge, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import { AppContext } from "../../AppContext";
import { useNavigate } from "react-router-dom";
import { Search } from "@mui/icons-material";
import SearchComp from "../Search/SearchComp";

function AppHeader() {
    const { productData, setProductData, isMobile, setSideMenu, setIsCheckingOut } = useContext(AppContext);
    const [searchExpanded, setSearchExpanded] = useState(false);
    const navigate = useNavigate();

    const totalProduct = () => {
        let total = 0
        if (productData?.checkoutProducts?.length > 0) {
            productData?.checkoutProducts.map(product => {
                total += product.quantity
            })
        }
        return total
    }

    return (
        <Box
            sx={{
                top: "0",
                right: "0",
                zIndex: "1000",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
                height: "100%",
            }}
        >

            <Box
                onClick={() => {
                    let defaultProd = productData.defaultProduct;
                    console.log("defaultProd", defaultProd)
                    setSideMenu(false)
                    setIsCheckingOut(false)
                    setProductData((prev) => {
                        return {
                            ...prev,
                            displayedProduct: {
                                mainProduct: defaultProd,
                                accessoryProduct: null,
                            },
                        };
                    });
                    navigate("/products")
                }}
                sx={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    padding: "20px",
                }}
            >

                <Box
                    component="img"
                    src={"/assets/images/logo512.png"}
                    sx={{
                        width: "30px",
                        fontSize: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                ></Box>
                <Typography
                    sx={{
                        display: { xs: "none", sm: "block" },
                        color: "white",
                        fontSize: "14px",
                        fontWeight: "500",
                    }}
                >
                    The Throne Corporation
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    margin: "20px"
                }}
            >
                {isMobile && !searchExpanded ? (
                    <IconButton
                        onClick={() => setSearchExpanded(true)}
                        sx={{ color: "white" }}
                    >
                        <Search />
                    </IconButton>
                ) : (
                    <SearchComp onBlur={() => setSearchExpanded(false)} />
                )}               
                
                <IconButton
                    onClick={() => { setIsCheckingOut(true) }}
                >
                    <Badge

                        badgeContent={totalProduct()}
                        sx={{
                            color: "white",
                            cursor: "pointer",
                            marginLeft: "auto",
                            "& .MuiBadge-badge": {
                                color: "white",
                                backgroundColor: "#95783B"
                            }
                        }}>
                        <ShoppingCartIcon
                            sx={{
                                color: "white",
                            }}
                        />
                    </Badge>
                </IconButton>
                <IconButton
                    onClick={() => { setSideMenu(true) }}
                    sx={{
                        color: "white",
                        marginLeft: "auto"
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Box>
        </Box>
    );
}

export default AppHeader;


