import React, { useContext, useEffect, useState, useRef } from "react";
import { Box, Typography, Paper } from '@mui/material';
import { AppContext } from "../../AppContext";
import { priceToUsdString } from "../../utilities/priceToUsd";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


function Recommended({ handleClose, onClose, isSideMenu, setCheckoutProduct }) {
    const { setLoading, recommendedItemsRef, setProductData, productData, isCheckingOut, setIsCheckingOut } = useContext(AppContext);
    const scrollContainerRef = useRef(null);
    const rightIndicatorRef = useRef(null);
    const [showLeftIndicator, setShowLeftIndicator] = useState(false);
    const [showRightIndicator, setShowRightIndicator] = useState(false);
    const navigate = useNavigate();

    const setProductUrl = (item) => {
        if (item.url) {
            const prodUrl = `/products/${item.url}`
            window.history.replaceState(null, '', prodUrl);
        }
    };

    const setNewProduct = (item) => {
        if (isCheckingOut) {
            setCheckoutProduct(item);
        } else {
            if (item.category_name === "Throne") {
                setLoading(0);
                setProductData((prev) => {
                    return {
                        ...prev,
                        displayedProduct: {
                            mainProduct: item,
                        }
                    }
                });
                setProductUrl(item);
                onClose && onClose();
            } else {
                setProductData((prev) => {
                    return {
                        ...prev,
                        displayedProduct: {
                            ...prev.displayedProduct,
                            accessoryProduct: item,
                        }
                    }
                });
                setProductUrl(item);
                onClose && onClose();
            }
        }
    };

    const handleWheel = (e) => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            const maxScrollLeft = container.scrollWidth - container.clientWidth;

            if ((e.deltaY > 0 && container.scrollLeft < maxScrollLeft) || (e.deltaY < 0 && container.scrollLeft > 0)) {
                e.preventDefault();
                container.scrollLeft += e.deltaY;
            }
        }
    };

    const handleIndicators = () => {
        if (scrollContainerRef.current) {
            const container = scrollContainerRef.current;
            const maxScrollLeft = container.scrollWidth - container.clientWidth;
            setShowLeftIndicator(container.scrollLeft > 0);
            setShowRightIndicator(container.scrollLeft < maxScrollLeft);
        }
    };

    const handleLinkToProducts = () => {
        if (isCheckingOut) {
            setIsCheckingOut(false);
        }
        handleClose && handleClose();
        onClose && onClose();
        navigate("/products");
    }

    useEffect(() => {
        const container = scrollContainerRef.current;
        const rightIndicator = rightIndicatorRef.current;
        if (container) {
            recommendedItemsRef.current = container;
            container.addEventListener('wheel', handleWheel, { passive: false });
            container.addEventListener('scroll', handleIndicators);
            if (isSideMenu) {
                container.style.width = "451px";
                if (rightIndicator) {
                    rightIndicator.style.right = "55px";
                }
            }
            handleIndicators();
        }
        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
                container.removeEventListener('scroll', handleIndicators);
            }
        };
    }, [scrollContainerRef.current, isSideMenu, recommendedItemsRef]);

    return (
        <Box
            sx={{
                position: "relative",
                minWidth: "100%",
                minHeight: "100%",
            }}
        >
            <Box
                sx={{
                    width: isSideMenu ? "431px" : { xs: "100%", sm: "500px", md: "600px", lg: "700px", xl: "100%" },
                    maxWidth: isSideMenu ? "431px" : { xs: "100%", sm: "500px", md: "600px", lg: "700px", xl: "100%" },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px 0",
                }}
            >

                <Typography
                    sx={{
                        fontWeight: "700",
                        fontSize: "1.2rem",
                        color: "white",
                    }}
                >
                    Recommended Items
                </Typography>

                <Typography
                    onClick={handleLinkToProducts}
                    sx={{
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "white",
                        textDecoration: "underline",
                        cursor: "pointer",
                    }}
                >
                    Shop All
                </Typography>
            </Box>
            {showLeftIndicator && (
                <Box
                    onClick={() => {
                        if (scrollContainerRef.current) {
                            const container = scrollContainerRef.current;
                            container.scrollLeft -= 100;
                        }
                    }}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        left: 0,
                        top: "70%",
                        transform: "translateY(-70%)",
                        backgroundColor: "rgba(24, 27, 29, 0.8)",
                        borderRadius: "12px",
                        color: "white",
                        cursor: "pointer",
                        width: "48px",
                        height: "48px",
                        border: "2px solid #95783B",
                    }}
                >
                    <NavigateBeforeIcon fontSize="large" />
                </Box>
            )}
            {showRightIndicator && (
                <Box
                    ref={rightIndicatorRef}
                    onClick={() => {
                        if (scrollContainerRef.current) {
                            const container = scrollContainerRef.current;
                            container.scrollLeft += 100;
                        }
                    }}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        right: isSideMenu ? "55px" : "0",
                        top: "70%",
                        transform: "translateY(-70%)",
                        backgroundColor: "rgba(24, 27, 29, 0.8)",
                        borderRadius: "12px",
                        color: "white",
                        cursor: "pointer",
                        width: "48px",
                        height: "48px",
                        border: "2px solid #95783B",
                    }}
                >
                    <NavigateNextIcon fontSize="large" />
                </Box>
            )}

            <Box
                ref={scrollContainerRef}
                sx={{
                    width: '100%',
                    maxWidth: isSideMenu ? '451px' : { xs: "100%", sm: "550px", md: "675px", lg: "760px", xl: "1000px" },
                    overflowX: "scroll",
                    display: 'flex',
                    gap: "10px",
                    flexDirection: "row",
                    alignItems: 'center',
                    justifyContent: "flex-start",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    '&::-webkit-scrollbar': {
                        display: "none",
                    },
                    webkitOverflowScrolling: 'touch',
                }}
            >
                {Object.keys(productData?.allProducts).map((key) => {
                    if (isCheckingOut) {
                        return (
                            <RecommendedCard
                                key={key}
                                item={productData?.allProducts[key]}
                                onClick={setNewProduct}
                            />
                        );
                    } else {
                        if (productData?.allProducts[key].id !== productData?.displayedProduct?.mainProduct?.id && productData?.allProducts[key].id !== productData?.displayedProduct?.accessoryProduct?.id) {
                            return (
                                <RecommendedCard
                                    key={key}
                                    item={productData?.allProducts[key]}
                                    onClick={setNewProduct}
                                />
                            );
                        }
                    }
                })}
                <Paper
                    onClick={handleLinkToProducts}
                    sx={{
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: 'center',
                        justifyContent: "center",
                        cursor: "pointer",
                        background: "#1F1F1F",
                        color: "white",
                        background: "#95783B",
                        minWidth: { xs: "120px", sm: "140px" },
                        minHeight: { xs: "120px", sm: "154px" },
                    }}
                >
                    <Typography
                        sx={{
                            width: "130px",
                            fontWeight: "600",
                            fontSize: "1.2rem",
                            textAlign: "center",
                        }}
                    >
                        Shop All
                    </Typography>
                </Paper>
            </Box>
        </Box>
    );
}

export default Recommended;

const RecommendedCard = ({ item, onClick }) => {
    return (
        <Paper

            onClick={() => { onClick(item); }}
            sx={{
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                justifyContent: "center",
                cursor: "pointer",
                padding: { xs: "4px", sm: "10px" },
                background: "#1F1F1F",
                color: "white",

            }}
        >
            <Box
                sx={{
                    width: { xs: "84px", sm: "96px" },
                    height: { xs: "84px", sm: "96px" },
                    backgroundImage: `url(${item?.default_image})`,
                    backgroundSize: "cover",
                    marginTop: "-10px",
                }}
            >
            </Box>
            <Typography
                sx={{
                    width: "120px",
                    display: '-webkit-box',
                    overflow: 'hidden',
                    fontWeight: "600",
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 1,
                }}
            >
                {item.name}
            </Typography>
            <Typography
                sx={{
                    fontWeight: "200",
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                    width: "100px",
                    alignSelf: 'flex-start',
                }}
            >
                {priceToUsdString(item?.price_details?.retail_price)}
            </Typography>
        </Paper>
    );
};
