
import React, { useContext } from "react";
import { Box } from '@mui/material';
import TabFooter from "../TabFooter";
import { AppContext } from "../../../../AppContext";

const Gallery = ({ mediaData }) => {
    const { setPreviewModalImage } = useContext(AppContext)

    const itemData = mediaData?.images


    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                display: 'inline-block',
                padding: { xs: "0", sm: "10px 0" }
            }}

        >

            <Box
                sx={{
                    marginTop:"20px",
                    display: 'grid',
                    gridTemplateColumns: "auto auto auto",
                    justifyItems: "center",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    alignContent: "center",
                    gap:"10px"
                }}
            >
                {itemData?.map((img, index) => (
                    <Box
                        key={index}
                        // component='img'
                        // src={`${img.public_url}?w=164&h=164&fit=crop&auto=format`}
                        sx={{
                            width: "100px",
                            height: "100px",
                            backgroundImage: `url(${img.public_url}?w=164&h=164&fit=crop&auto=format)`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            cursor: "pointer",
                        }}

                        onClick={() => { setPreviewModalImage(img.public_url) }}
                    >
                    </Box>
                ))}
            </Box>
            <TabFooter /> 
            < Box />
        </Box>

    );
}


export default Gallery;


