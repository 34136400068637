
import React, { useContext } from "react";
import { Box, Typography } from '@mui/material';
import { CheckoutContext } from "../CheckoutModal";
import { getTotalPrice } from "./getTotalPrice";

function CheckoutTotalPrice() {

    const { checkoutProducts } = useContext(CheckoutContext);

    return (
        <Box
            sx={{
                top: "0",
                left: "0",
                zIndex: "1000",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
            }}
        >

            <Box
                sx={{
                    width: "100%",
                    marginBottom: "40px",
                    padding: "0 40px"
                }}
            >
                <Typography
                    sx={{
                        fontSize: {xs:"1rem", md:"24px"},
                        fontWeight: "400",
                        fontFamily: "Inter",
                        color: "white",
                    }}                
                >
                    Payment to Throne Tools Corporation
                </Typography>
                <Typography 
                    sx={{
                        fontSize: {xs:"24px", md:"48px"},
                        fontWeight: "400",
                        fontFamily: "Inter",
                        color: "white",
                    }}
                >{
                    getTotalPrice(checkoutProducts)
                }</Typography>
            </Box>

        </Box>
    );
}

export default CheckoutTotalPrice;


