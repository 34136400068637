
import { Box, styled, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import TabFooter from "../TabFooter";

const Specs = ({ specsData }) => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: 'inline-block',
                padding: { xs: "20px 0", sm: "10px 0" }
            }}

        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                }}
            >


                <TableContainer
                    sx={{
                        backgroundColor: "transparent",
                        width: "100%",
                        maxWidth: "350px"
                    }}
                >
                    <Table aria-label="simple table"
                    >
                        {specsData?.map((spec, index) => (
                            <TableBody key={index}>
                                <TableRow>
                                    <StyledTableCell>{spec.name}</StyledTableCell>
                                    <StyledTableCell align="left">{spec.description}</StyledTableCell>
                                </TableRow>
                            </TableBody>
                        ))}

                    </Table>
                </TableContainer>

                <TabFooter />

            </Box>
        </Box>

    );
}

export default Specs;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    border: "1px solid white",
    color: 'white',
    paddingRight: "100px"
}));