import { Box, Typography } from '@mui/material';
import { PrimaryButton } from '../components/Buttons/Buttons';

function FailPaymentPageComp(props) {
    const checkoutHandler = () => {
        window.open(`${window.location.origin}`, "_self")
    }

    return (
            <Box sx={{
                position: 'absolute',
                top: '0',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "40px",
                transition: 'opacity 1s',
                opacity: '1',
                zIndex: '1400',
                backgroundColor: "#262626",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                fontWeight: 600,
            }}>
                <Box
                    component="img"
                    src={"/assets/images/LogoFailIcon.svg"}
                    sx={{
                        fontSize: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                ></Box>

                <Typography
                    sx={{
                        color: 'white',
                        fontSize: "22px"
                    }}
                >Payment Failed!</Typography>
                <Typography
                    sx={{
                        color: 'white',
                        maxWidth: "450px",
                        textAlign: "center",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                    }}
                >Your transaction has failed due to some technical error.
                    Please try again.</Typography>
                <Box
                    sx={{
                        width: "100%",
                        gap: "10px",
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxWidth: "350px"
                    }}
                >
                    <PrimaryButton
                        sx={{
                            backgroundColor: "#F15249",
                            border: "0",
                        }}
                        onClick={checkoutHandler}
                    >try again</PrimaryButton>
                </Box>
            </Box>
    );
}

export default FailPaymentPageComp;


