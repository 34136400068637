import { Box, Input, InputBase, InputAdornment } from "@mui/material";
import { styled } from '@mui/system';
import { useEffect, useState, useContext, useCallback } from "react";
import { AppContext } from "../../AppContext";
import SearchResults from "./common/SearchResults";
import { Search as SearchIcon } from "@mui/icons-material";


const SearchComp = () => {
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const { stripeAPI, setProductData } = useContext(AppContext);

  const handleSearch = useCallback(async () => {
    if (search.length > 0) {
      setLoadingSearchResults(true);
      try {
        const res = await fetch(`${stripeAPI}/api/v1/products/search?search=${search}`);
        const data = await res.json();
        setSearchResults(data.data.products);
        setShowResults(true);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingSearchResults(false);
      }
    } else {
      setSearchResults([]);
      setShowResults(false);
    }
  }, [search, stripeAPI]);

  const handleSearchResultClick = (product) => {
    setSearch("");
    setShowResults(false);
    setSearchResults([]);
    if (product.category_name === "Throne") {
      setProductData((prev) => {
        return {
          ...prev,
          displayedProduct: {
            mainProduct: product,
            accessoryProduct: null,
          },
        };
      });
    } else {
      setProductData((prev) => {
        return {
          ...prev,
          displayedProduct: {
            ...prev.displayedProduct,
            accessoryProduct: product,
          },
        };
      });
    }
  }

  useEffect(() => {
    handleSearch();
  }, [search, handleSearch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!document.getElementById('search-results')?.contains(event.target) &&
        !document.getElementById('search')?.contains(event.target)) {
        setShowResults(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "10px",
        width: "100%",
      }}
    >
      <SearchInput
        id="search"
        label="Search"
        variant="outlined"
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        autoComplete="off"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon sx={{ color: "#fff" }} />
          </InputAdornment>
        }
      />
      {showResults && (
        <SearchResults
          isLoadingSearchResults={loadingSearchResults}
          searchResults={searchResults}
          handleSearchResultClick={handleSearchResultClick}
        />
      )}
    </Box>
  );
};

export default SearchComp;

const SearchInput = styled(InputBase)(({ theme }) => ({
  width: "100%",
  maxWidth: "300px",
  height: "40px",
  borderRadius: "8px",
  padding: "0 10px",
  color: "#fff",
  fontWeight: "thin",
  fontFamily: "inherit",
  fontStyle: "italic",
  fontSize: "16px",
  backgroundColor: "#33393E",
  border: "1px solid #ddd",
  display: "flex",
  alignItems: "center",
  "&:focus-within": {
    borderColor: "#000",
  },
  "& .MuiInputAdornment-root": {
    marginRight: "10px",
  },
  "& .MuiInputBase-input": {
    padding: "8px 0",
  },
  "&::placeholder": {
    color: "#ddd",
  },
}));
