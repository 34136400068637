import * as THREE from 'three';

const GenerateBlowOutAnimation = (inputGltf) => {

    const times = [0, 6.95, 6];
    const gltfScene = inputGltf.scene.clone()
    const tracks = [];
    const length = -1;
    const tempGrp = new THREE.Group()
    const objectsList = []
    // iteration
    // console.log({ gltfScene })

    // gltfScene?.traverse((child) => {
    //     if (child instanceof THREE.Mesh) {
    //         const initPosition = child.position.clone()
    //         const parent = child.parent
    //         const newPosition = generateRandomPosition()
    //         tempGrp.attach(child)
    //         if (child.position.y < 0) child.position.y = 0
    //         tempGrp.position.copy(newPosition)
    //         parent.attach(child)
    //         const animatPosition = child.position.clone()
    //         child.position.copy(initPosition)

    //         const values = [
    //             initPosition.x, initPosition.y, initPosition.z,
    //             animatPosition.x, animatPosition.y, animatPosition.z,
    //             animatPosition.x, animatPosition.y, animatPosition.z
    //         ];
    //         // // const parentMesh = child.parent instanceof THREE.Mesh
    //         // // if (!parentMesh) {
    //         // //     objectsList.push(child)
    //         // // }



    //         const positionKF = new THREE.VectorKeyframeTrack(`${child.name}.position`, times, values);
    //         tracks.push(positionKF)
    //     }

    // });

    gltfScene.children.map((child) => {

        const initPosition = child.position.clone()
        // const parent = child.parent
        let iteration = 0
        let newPosition = generateRandomPosition()
        // tempGrp.attach(child)
        // if (child.position.y < 0) child.position.y = 0
        // tempGrp.position.copy(newPosition)
        // parent.attach(child)
        // const animatPosition = child.position.clone()
        // child.position.copy(initPosition)
        let overlap = true;
        // console.log(gltfScene)
        const others = gltfScene.children.filter(obj => obj != child)
        while (overlap) {
            newPosition = generateRandomPosition()
            child.position.copy(newPosition)
            overlap = checkOverlap(child, others);
            if (iteration > 20) break
            iteration += 1
        }
        const values = [
            initPosition.x, initPosition.y, initPosition.z,
            newPosition.x, newPosition.y, newPosition.z,
            newPosition.x, newPosition.y, newPosition.z
        ];
        // // const parentMesh = child.parent instanceof THREE.Mesh
        // // if (!parentMesh) {
        // //     objectsList.push(child)
        // // }

        const positionKF = new THREE.VectorKeyframeTrack(`${child.name}.position`, times, values);
        tracks.push(positionKF)

    });

    // objectsList.forEach(mesh => {
    //     let initPosition = mesh.position.clone()
    //     let newPosition
    //     let overlap = true;
    //     let iteration = 0

    //     while (overlap) {
    //         newPosition = generateRandomPosition()
    //         mesh.position.copy(newPosition)
    //         overlap = checkOverlap(mesh, objectsList);
    //         if (iteration > 20) break
    //         iteration += 1
    //     }

    //     const values = [
    //         initPosition.x, initPosition.y, initPosition.z,
    //         newPosition.x, newPosition.y, newPosition.z,
    //         newPosition.x, newPosition.y, newPosition.z
    //     ];

    //     const positionKF = new THREE.VectorKeyframeTrack(`${mesh.name}.position`, times, values);
    //     tracks.push(positionKF)

    // })

    const clip = new THREE.AnimationClip("3Mera Animation", length, tracks);
    return clip
}

export default GenerateBlowOutAnimation


function generateRandomPosition() {

    const scale = 1.5
    const position = new THREE.Vector3(
        (Math.random() - 0.5) * scale,
        (Math.random()) * scale / 2,
        (Math.random() - 0.5) * scale
    )
    return position
}

function checkOverlap(thisObject, checklist) {
    const objBox = new THREE.Box3().setFromObject(thisObject)
    objBox.expandByScalar(0.1)
    checklist.forEach(otherMesh => {
        const otherhBox = new THREE.Box3().setFromObject(otherMesh)
        const collision = objBox.intersectsBox(otherhBox);
        if (collision) {
            // console.log({collision})
            return true; // Overlap found
        }
    });

    return false; // No overlap
}