import { useEffect, useState, useContext } from "react";
import { Box, Modal, Typography, Tooltip } from "@mui/material";
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from "../AppDetail/Tabs/Tabs";
import AppFooter from "../AppFooter/AppFooter";
import { AppContext } from "../../AppContext";
import { PrimaryButton } from "../Buttons/Buttons";
import Gallery from "../Checkout/Comps/PreviewProductFooter/Gallery";

function ProductModal({ product, setCheckoutProduct, handleClose }) {
  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const { isCheckingOut, setIsCheckingOut, setProductData } = useContext(AppContext);

  const handleViewModel = (item) => {
    setIsCheckingOut(false);
    setProductData((prev) => {
      return {
        ...prev,
        displayedProduct: {
          mainProduct: item,
          accessoryProduct: null,
        }
      }
    });
    // Change url to item.url but dont reload page
    window.history.pushState({}, null, item.url);
    handleClose();
  }


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Modal
      open={true}
      disableAutoFocus={true}
      onClose={() => { handleClose() }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "2002",
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "85%",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "550px",
          borderRadius: "10px",
          overflow: "hidden",
          backgroundColor: "#262626",
          padding: { xs: "20px", sm: "40px" },
        }}
      >

        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            position: "relative",
          }}

        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",

            }}

          >
            <CloseButton onClick={() => { handleClose() }} />
            <Tooltip title={product.name} arrow placement="top" >
              <Typography
                onClick={handleExpandClick}
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                  fontWeight: "600",
                  whiteSpace: expanded ? "normal" : "nowrap",
                  overflow: expanded ? "visible" : "hidden",
                  textOverflow: expanded ? "clip" : "ellipsis",
                  cursor: expanded ? "default" : "pointer",
                  width: "80%",
                }}
              >
                {product.name}
              </Typography>
            </Tooltip>

            <Box
              sx={{
                width: "100%",
                height: "2px",
                backgroundColor: "#F3F2F4"
              }}
            >

            </Box>
          </Box>



          <Box
            sx={{
              margin: "5px 0",
            }}
          >
            <Gallery previewProduct={product} />

          </Box>

          <Box
            sx={{
              top: "0",
              left: "0",
              zIndex: "1000",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                width: '100%',
                gap: '5px',
                margin: "10px 0"
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>

                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    fontWeight: "600",
                    textOverflow: "ellipsis",
                  }}
                >Price:</Typography>

                <Typography
                  sx={{

                    fontWeight: "600",
                    fontSize: { xs: "1rem", sm: "1.2rem" },
                  }}
                > {Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: product.price_details.currency,
                }).format(product.price_details.retail_price)}</Typography>
              </Box>

              {product?.url && product?.model_file || product?.url && product?.animation_file ?
                <PrimaryButton 
                  sx={{width: "150px"}}
                  onClick={() => { handleViewModel(product) }}
                >
                  View In 3D
                </PrimaryButton>
              : null}
            </Box>


          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

            }}
          >
            <Tabs isAsscessorieModal={true} product={product} setCheckoutProduct={setCheckoutProduct} handleClose={handleClose} />
          </Box>

        </Box>

        <Box
          sx={{
            position: 'absolute',
            width: "calc(100% + 20px)",
            bottom: "0",
            left: "-10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
            padding: "4px 0",
            backgroundImage: "linear-gradient(to top, #262626,#262626, #ffffff00)"
          }}
        >
          <AppFooter />
        </Box>
      </Box>

    </Modal>
  )
}

export default ProductModal;

const CloseButton = styled(CloseIcon)((props) => ({
  position: "sticky",
  right: "2px",
  top: "2px",
  width: "20px",
  height: "20px",
  color: "#7F7F7F",
  cursor: "pointer",
  border: "2px solid #7F7F7F",
  padding: "3px",
  borderRadius: "100px",
  transitionProperty: "transform",
  transitionDuration: " 0.2s",
  alignSelf: "flex-end",
  '&:hover': {
    transform: "scale(1.1)",
  },
}));