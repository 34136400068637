"use client";
import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, Tabs, Tab, Card, CardMedia, Grid, IconButton } from "@mui/material";
import { AppContext } from "../../AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack, CloseRounded } from "@mui/icons-material";

function ProductPage() {
  const { productData, setProductData } = useContext(AppContext);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Categories");
  const [displayedProducts, setDisplayedProducts] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const getCategories = () => {
    let categories = ["Categories", "All Products"];
    let allProducts = productData.allProducts;
    allProducts.forEach(product => {
      if (!categories.includes(product.category_name)) {
        categories.push(product.category_name);
      }
    });
    setCategories(categories);
  };

  const filterProducts = (category) => {
    if (category === "All Products") {
      setDisplayedProducts(productData.allProducts);
    } else if (category === "Categories") {
      setDisplayedProducts([]);
    } else {
      setDisplayedProducts(productData.allProducts.filter(product => product.category_name === category));
    }
  };

  const handleCategoryChange = (event, newValue) => {
    setSelectedCategory(newValue);
  };

  const handleCategoryClicked = (category) => {
    setSelectedCategory(category);
  }

  const handleBackClicked = () => {
    // get current displayed main product, and if there is an accessory product, that is the url to go back to
    // navigate back 
    navigate(-1);
  }

  const handleProductClicked = (product) => {
    if (product.category_name === "Throne") {
      setProductData((prev) => {
        return {
          ...prev,
          displayedProduct: {
            mainProduct: product,
          }
        }
      });
    } else {
      setProductData((prev) => {
        return {
          ...prev,
          displayedProduct: {
            ...prev.displayedProduct,
            accessoryProduct: product,
          }
        }
      });
    }
    window.history.pushState({}, null, `/products/${product.url}`);
  }

  const getCategoryImage = (category) => {
    const product = productData.allProducts.find(product => product.category_name === category);
    return product ? product.default_image : "https://via.placeholder.com/200";
  };

  useEffect(() => {
    getCategories();
  }, [productData.allProducts]);

  useEffect(() => {
    filterProducts(selectedCategory);
  }, [selectedCategory, productData.allProducts]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get("category");
    if (category) {
      setSelectedCategory(category);
    } else {
      setSelectedCategory("Categories");
    }
  }, [location.search]);

  return (
    <Box
      sx={{
        marginTop: "80px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "white",
        overflow: 'auto',
        padding: "0 20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <IconButton onClick={handleBackClicked} sx={{ display: { xs: "block", sm: "none" } }}>  
          <ArrowBack sx={{ color: "white" }} />
        </IconButton>
      <Tabs
        value={selectedCategory}
        onChange={handleCategoryChange}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        arrow="false"
        sx={{ borderBottom: 1, borderColor: '#fff', width: "100%" }}
      >
        {categories.length > 0 && categories.map((category, index) => (
          <Tab 
            key={index} 
            label={category} 
            value={category} 
            sx={{ 
              color: "white", 
              fontSize: { xs: "12px", sm: "16px" },
              '&.Mui-selected': { color: '#95783B', fontWeight: "800" } 
            }} 
          />
        ))}
        <Tab
          value=""
          label={<CloseRounded sx={{ color: "white" }} />}
          onClick={handleBackClicked}
          sx={{
            position: "absolute",
            right: 0,
            display: { xs: "none", sm: "block" },
            color: "white",
            fontSize: { xs: "12px", sm: "16px" },
            '&.Mui-selected': { color: '#95783B', fontWeight: "800" }
          }}
        />
      </Tabs>
      </Box>
      <Grid container spacing={3} sx={{ mt: 2, width: "100%" }}>
        {selectedCategory === "Categories" ? (
          categories.slice(2).map((category, index) => (
            <Grid item xs={6} sm={6} md={4} lg={4} key={index}>
              <CategoryCard key={index} category={category} handleCategoryClicked={handleCategoryClicked} categoryImage={getCategoryImage(category)} />
            </Grid>
          ))
        ) : (
          displayedProducts.length > 0 && displayedProducts.map((product, index) => (
            <Grid item xs={6} sm={6} md={4} lg={4} key={index}>
              <ProductCard key={index} product={product} handleProductClicked={handleProductClicked} />
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
}

export default ProductPage;

function ProductCard({ product, handleProductClicked }) {
  return (
    <Card
      onClick={() => handleProductClicked(product)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "5px",
        cursor: "pointer",
        padding: "10px",
        backgroundColor: "#262626",
        color: "white",
      }}
    >
      <CardMedia
        component="img" 
        image={product.default_image}
        alt={product.name}
        sx={{
          objectFit: "contain",
          backgroundSize: "contain",
          borderRadius: "8px",
          height: { xs: "150px", sm: "200px" },
          width: "100%",
        }}
      />
      <Typography
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontWeight: "600",
          maxWidth: "100%",
          fontSize: { xs: "14px", sm: "18px" },
          textOverflow: "ellipsis",
        }}
      >{product.name}</Typography>
      <Typography
        sx={{
          fontWeight: "600",
          color: "#95783B",
          fontSize: { xs: "14px", sm: "18px" },
        }}
      >$ {product.price_details.retail_price}</Typography>
    </Card>
  );
}

function CategoryCard({ category, handleCategoryClicked, categoryImage }) {
  return (
    <Card
      onClick={() => handleCategoryClicked(category)}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "5px",
        cursor: "pointer",
        padding: "10px",
        backgroundColor: "#262626",
        color: "white",
      }}
    >
      <CardMedia
        component="img"
        image={categoryImage}
        alt={category}
        sx={{
          objectFit: "contain",
          backgroundSize: "contain",
          borderRadius: "8px",
          height: { xs: "150px", sm: "200px" },
          width: "100%",
        }}
      />
      <Typography
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontWeight: "600",
          maxWidth: "100%",
          textOverflow: "ellipsis",
        }}
      >{category}</Typography>
    </Card>
  );
}
