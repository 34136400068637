
import React, { useContext, useRef, useState } from "react";
import { Box, Typography, styled } from '@mui/material';
import Detail from "./Comp/Detail";
import Specs from "./Comp/Specs";
import Gallery from "./Comp/Gallery";
import Video from "./Comp/Video";
import FAQs from "./Comp/FAQs";

import { AppContext } from "../../../AppContext";

function Tabs({ isAsscessorieModal, product, setCheckoutProduct, handleClose }) {
    const [tabIndex, setTabIndex] = useState(0)
    const { productData } = useContext(AppContext)
    const [scrollTop, setScrollTop] = useState(0)
    const containerRef = useRef()

    const setScroll = (value) => {
        if (typeof value != "number") {
            setScrollTop(0)
        } else {
            let newScroll = scrollTop + value
            if (newScroll < 0) {
                newScroll = 0
            } else if (newScroll > containerRef.current.clientHeight
            ) {
                newScroll = containerRef.current.clientHeight
            }
            containerRef.current.scrollTo(0, newScroll)
            setScrollTop(newScroll)
        }

    }
    let tabData = [
        {
            title: "Details",
            component: isAsscessorieModal ?
                <Detail handleClose={handleClose} setCheckoutProduct={setCheckoutProduct} detailData={productData?.displayedProduct?.accessoryProduct?.description || product?.description} isAsscessorieModal={isAsscessorieModal} />
                : <Detail handleClose={handleClose} detailData={productData?.displayedProduct?.mainProduct?.description} isAsscessorieModal={false} />,
            data: isAsscessorieModal ? productData?.displayedProduct?.accessoryProduct?.description || product.description
                : productData?.displayedProduct?.mainProduct?.description || product.description
        },
        {
            title: "Specs",
            component: isAsscessorieModal ? <Specs specsData={productData?.displayedProduct?.accessoryProduct?.tabs?.specifications || product?.tabs?.specifications} />
                : <Specs specsData={productData?.displayedProduct?.mainProduct?.tabs?.specifications || product?.tabs?.specifications} />,
            data: isAsscessorieModal ? productData?.displayedProduct?.accessoryProduct?.tabs?.specifications || product?.tabs?.specifications
                : productData?.displayedProduct?.mainProduct?.tabs?.specifications || product?.tabs?.specifications
                
        },
        {
            title: "Gallery",
            component: isAsscessorieModal ? <Gallery mediaData={productData?.displayedProduct?.accessoryProduct?.tabs?.media || product?.tabs?.media} />
                : <Gallery mediaData={productData?.displayedProduct?.mainProduct?.tabs?.media} />,
            data: isAsscessorieModal ? productData?.displayedProduct?.accessoryProduct?.tabs?.media || product?.tabs?.media
                : productData?.displayedProduct?.mainProduct?.tabs?.media || product?.tabs?.media
        },
        {
            title: "Video",
            component: isAsscessorieModal ? <Video mediaData={productData?.displayedProduct?.accessoryProduct?.tabs?.media || product?.tabs?.media} />
                : <Video mediaData={productData?.displayedProduct?.mainProduct?.tabs?.media} />,
            data: isAsscessorieModal ? productData?.displayedProduct?.accessoryProduct?.tabs?.media?.videos || product?.tabs?.media?.videos
                : productData?.displayedProduct?.mainProduct?.tabs?.media?.videos || product?.tabs?.media?.videos
        },
        {
            title: "FAQs",
            component: isAsscessorieModal ? <FAQs faqData={productData?.displayedProduct?.accessoryProduct?.tabs?.faqs || product?.tabs?.faqs} />
                : <FAQs faqData={productData?.displayedProduct?.mainProduct?.tabs?.faqs} />,
            data: isAsscessorieModal ? productData?.displayedProduct?.accessoryProduct?.tabs?.faqs || product?.tabs?.faqs
                : productData?.displayedProduct?.mainProduct?.tabs?.faqs || product?.tabs?.faqs
        },
    ];

    tabData = tabData.filter(tab => {
        return Array.isArray(tab.data) ? tab.data.length > 0 : tab.data
    })


    const addTabIndex = (value) => {

        let newIndex = tabIndex + value
        if (newIndex < 0) {
            newIndex = tabData.length - 1
        } else if (newIndex >= tabData.length) {
            newIndex = 0
        }
        setTabIndex(newIndex)

    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "700px",
                width: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: { xs: "center", md: "flex-start" },
                    alignItems: { xs: "center", md: "flex-start" },
                    position: "relative",
                    gap: "10px",
                    borderBottom: "2px solid #979797"
                }}
            >
                {tabData?.map((tab, index) => (
                    <Box
                        key={index}
                        sx={{
                            padding: "4px 0",
                            borderBottom: tabIndex === index ? "2px solid #F6923A" : "none",
                        }}
                    >
                        <Title key={tab.title} onClick={() => { setTabIndex(index) }} active={tabIndex === index ? "true" : null} >{tab.title}</Title>
                    </ Box>
                ))}
            </Box>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    color: "white",
                    overflowY: "scroll",
                    overflowX: "hidden",
                    width: "100%",
                    flex: 1,
                    scrollbarWidth: "none", // Hide the scrollbar for firefox
                    '&::-webkit-scrollbar': {
                        display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                    },
                    '&-ms-overflow-style:': {
                        display: 'none', // Hide the scrollbar for IE
                    },
                    '&::WebkitOverflowScrolling': 'touch',
                }}
                ref={containerRef}
            >
                <TabContent setScroll={setScroll} tabData={tabData} tabIndex={tabIndex} addTabIndex={addTabIndex} />
            </Box>
        </Box>
    );
}

export default Tabs;



const TabContent = ({ setScroll, tabData, tabIndex, addTabIndex }) => {
    const [start, setStart] = useState(0)
    const [startY, setStartXY] = useState(0)
    const [offsetX, setOffsetX] = useState(0)
    const [preY, setPreY] = useState(0)
    const [isScrollX, setIsScrollX] = useState(true)
    const [index, setIndex] = useState(0)
    const [cantTransition, setCanTransition] = useState(false)
    const { recommendedItemsRef } = useContext(AppContext)

    const tabRef = useRef()

    const getCurrentTab = (index) => {

        if (index < 0) {
            index = tabData.length - 1
        } else if (index > tabData.length - 1) {
            index = 0
        }
        // console.log({ index })

        const tab = tabData[index].component
        return tab
    }

    const getOffset = () => {
        if (offsetX) return `calc(-${(index + 1) * 200}% + ${offsetX}px)`
        return `calc(-${(index + 1) * 200}%)`
    }

    return (

        <Box
            ref={tabRef}
            onTouchStart={(e) => {
                setStart(e.touches[0].pageX)
                setStartXY(e.touches[0].pageY)
                setPreY(e.touches[0].pageY)
                setIsScrollX(true)
                if (recommendedItemsRef.current) {
                    const rect = recommendedItemsRef.current.getBoundingClientRect();
                    if (e.touches[0].clientY > rect.top && e.touches[0].clientY < rect.bottom) {
                        setIsScrollX(false);
                    }
                }
            }}
            onTouchMove={(e) => {
                const _offsetX = e.touches[0].pageX - start
                const _offsetY = startY - e.touches[0].pageY
                // console.log({ _offsetX, _offsetY })
                // console.log(e.touches[0])
                if (Math.abs(_offsetX) < Math.abs(_offsetY)) {
                    setIsScrollX(false)
                } else {

                }

                // console.log({isScrollX})
                if (isScrollX) {
                    setOffsetX(_offsetX)
                } else {
                    const deltaY = preY - e.touches[0].pageY
                    setScroll(deltaY)
                    // setCanScroll(true)
                }

                setPreY(e.touches[0].pageY)

            }}
            onTouchEnd={(e) => {
                if (offsetX > 20) {
                    setIndex(-1)
                } else if (offsetX < -20) {
                    setIndex(1)
                }
                setIsScrollX(false)
                setOffsetX(0)
                setCanTransition(true)
                // setCanScroll(false)
            }}
            onTransitionEnd={(e) => {
                addTabIndex(index)
                setIndex(0)
                setIsScrollX(false)
                setCanTransition(false)
                // setCanScroll(false)
            }}
            sx={{
                marginLeft: getOffset(),
                transition: !cantTransition ? "none" : "margin-left 0.5s",
                width: "100%",
                display: '-webkit-inline-box',
                position: "relative",
            }}
        >
            <Box
                sx={{
                    width: "100%",
                }}
            >
                {getCurrentTab(tabIndex - 1)}
            </Box>

            <Box
                sx={{
                    width: "100%",
                }}
            >
                {getCurrentTab(tabIndex)}
            </Box>
            <Box
                sx={{
                    width: "100%",

                }}
            >
                {getCurrentTab(tabIndex + 1)}
            </Box>
        </Box>
    )
}



const Title = styled(Typography)(({ active }) => ({
    color: "white",
    textAlign: "center",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: active ? "700" : "300",
    lineHeight: "normal",
    cursor: "pointer",
    "&:hover": {
        background: "#c3c3c329",
    },
}));



