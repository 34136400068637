import { useEffect, useState, useContext } from "react";
import { Box, Modal, Typography, Tooltip } from "@mui/material";
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from "../AppDetail/Tabs/Tabs";
import AppFooter from "../AppFooter/AppFooter";
import { AppContext } from "../../AppContext";
import Gallery from "../Checkout/Comps/PreviewProductFooter/Gallery";
import ProductModal from "../Modal/ProductModal";

function AccessoriesPopup({ product }) {
  const { productData, setProductData } = useContext(AppContext);
  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, [product]);

  const handleClose = () => {
    setProductData((prev) => {
      return {
        ...prev,
        displayedProduct: {
          ...prev.displayedProduct,
          accessoryProduct: null,
        },
      };
    });
    setOpen(false);
    let currentProd = productData.displayedProduct.mainProduct.url;
    const prodUrl = `/products/${currentProd}`
    window.history.replaceState(null, '', prodUrl);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <ProductModal product={product} handleClose={handleClose} />
  );
}

export default AccessoriesPopup;


const CloseButton = styled(CloseIcon)((props) => ({
  position: "sticky",
  right: "2px",
  top: "2px",
  width: "20px",
  height: "20px",
  color: "#7F7F7F",
  cursor: "pointer",
  border: "2px solid #7F7F7F",
  padding: "3px",
  borderRadius: "100px",
  transitionProperty: "transform",
  transitionDuration: " 0.2s",
  alignSelf: "flex-end",
  '&:hover': {
    transform: "scale(1.1)",
  },
}));