"use client";
import { Box } from "@mui/material";
import React from "react";

function PrivacyPolicy(props) {
  const privacyPolicy = props?.storePolicy?.WebsitePrivacyPolicy;
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "auto",
      }}
    >
      <Box
        dangerouslySetInnerHTML={{ __html: privacyPolicy?.policy_text }}
        sx={{
          width: "90%",
          height: "100%",
          padding: "4px",
          textAlign: "justify",
        }}

      />
    </Box>
  );
}

export default PrivacyPolicy;


