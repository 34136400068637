
import { Box } from "@mui/material";

function TermOfUse(props) {
    const termOfUse = props?.storePolicy?.TermOfUse;
    return (
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Box
                dangerouslySetInnerHTML={{ __html: termOfUse?.policy_text }}
                sx={{
                    width: "95%",
                    height: "100%",
                    padding: "0px 10px 90px 10px",
                    textAlign: "justify",
                    overflow: "auto",
                }}
            />
        </Box>
    );
}

export default TermOfUse;


