
import React, { useState } from "react";
import { Box, Typography, styled, Modal, } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import VideoModal from "./VideoModal";
import { pushToDataLayer } from "../../utilities/GoogleAnalytics";


const Title = styled(Typography)(() => ({
    color: "white",
    textAlign: "center",
    fontFamily: "'Inter', sans-serif",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "normal",
}));

const Content = styled(Typography)(() => ({
    color: "white",
    width: "100%",
    textAlign: "start",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
}));

function ARErrorModal({ onClose }) {
    const [showVideo, setShowVideo] = useState(false)
    return (
        <Modal
            open={true}
            disableAutoFocus={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <>
                <Box sx={{
                    position: 'absolute',
                    top: '0',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#282828e0',
                    transition: 'opacity 1s',
     
                    opacity: '1',
                    gap: "40px",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    fontWeight: 600,
                    zIndex: "2000",
                
                }}>

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: 'column',
                            gap: "30px",
                            padding:"20px",
                        }}
                    >
                        <Title>Scan QR Code with AR Compatible Device</Title>

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                gap: "10px"
                            }}
                        >
                            <Content>1. Open camera on your smartphone or tablet</Content>
                            <Content>2. Scan QR Code with your smartphone or tablet</Content>
                            <Content>3. Click to open code link on your device</Content>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: "5px"
                            }}

                        >
                            <PlayArrowIcon
                                onClick={() => {
                                    setShowVideo(true)
                                    pushToDataLayer({ 'event': 'btn_clicked', 'clicked_button': 'watch_tutorial' })
                                }}
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    color: "white",
                                    backgroundColor: "#95783B",
                                    padding: "3px",
                                    borderRadius: "100px",
                                    cursor: "pointer",
                                    transitionProperty: "transform",
                                    transitionDuration: " 0.2s",
                                    '&:hover': {
                                        transform: "scale(1.1)",
                                    },
                                }} />

                            <Title
                                onClick={() => {
                                    setShowVideo(true)
                                    pushToDataLayer({ 'event': 'btn_clicked', 'clicked_button': 'watch_tutorial' })
                                }}
                                sx={{
                                    cursor: "pointer",
                                    '&:hover': {
                                        textDecoration: "underline",
                                    },
                                }}
                            >Stuck? Watch the tutorial</Title>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            padding: '10px',
                            backgroundColor: "white"
                        }}
                    >
                        <QRCodeSVG size={200} value={window.location.href} />
                    </Box>



                    <CloseIcon
                        onClick={() => {
                            onClose()
                            pushToDataLayer({ 'event': 'btn_clicked', 'clicked_button': 'qr_code_close' })
                        }}
                        sx={{
                            position: 'absolute',
                            right: "0",
                            top: "0",
                            margin: "20px",
                            width: "30px",
                            height: "30px",
                            color: "#7F7F7F",
                            cursor: "pointer",
                            border: "2px solid #7F7F7F",
                            padding: "3px",
                            borderRadius: "100px",
                            transitionProperty: "transform",
                            transitionDuration: " 0.2s",
                            '&:hover': {
                                transform: "scale(1.1)",
                            },
                        }}

                    ></CloseIcon>
                </Box>
                <VideoModal showVideo={showVideo} setShowVideo={setShowVideo} />
            </>
        </Modal>
    );
}

export default ARErrorModal;


