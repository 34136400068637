import { Vector3, Object3D, Group } from 'three';
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

import Mesure from './Mesure';

export default class Reference extends Object3D {
    constructor() {
        super()

        this.gltfLoader = new GLTFLoader();
        this.mesure = new Mesure(120)
        this.person = new Group();


        this.gltfLoader.load("/assets/models/person.glb", (gltf) => {
            this.person.rotation.y = -2

            this.person.add(gltf.scene);
        }, undefined, function (error) {
            console.error(error);
        });
        // this.add(this.mesure)
        // this.add(this.person)
    }


    build(box) {
        const size = {
            width: Number((box.max.x - box.min.x).toFixed(2)),
            height: Number((box.max.y - box.min.y).toFixed(2)),
            depth: Number((box.max.z - box.min.z).toFixed(2))
        }

        const center = new Vector3()
        box.getCenter(center)
        this.mesure.position.set(center.x, center.y, center.z)
        this.person.position.x = -center.x - size.width - 0.3
        this.person.position.z = - center.z - size.depth
        this.mesure.build(size)
        // this.mesure.position.y = -box.min.y
    }

    updateStyle(style) {


        switch (style) {
            case "mesure":
                this.add(this.mesure)
                this.remove(this.person)
                break;
            case "person":
                this.remove(this.mesure)
                this.add(this.person)
                break;
            case "all":
                this.add(this.mesure)
                this.add(this.person)
                break;
            default:
                this.remove(this.mesure)
                this.remove(this.person)
                break;
        }
    }

}

