
import { Button, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const PrimaryButton = styled(Button)((props) => ({
    height: "35px",
    color: "white",
    fontSize: "0.8rem",
    display: "flex",
    width: "70%",
    fontFamily: "'Inter', sans-serif",
    maxWidth: "350px",
    backgroundColor: "#95783B",
    // 'border': '3px solid #972113',
    borderRadius: "60px",
    padding: "4px 0",
    gap: "10px",
    animation: "3s infinite pop",
    fontWeight: 600,
    "&:hover": {
        background: "#95783B",
        transform: "scale(1.1)",
    },
    transitionProperty: "transform",
    transitionDuration: " 0.2s",
}));

const SecondaryButton = styled(Button)((props) => ({
    height: "35px",
    color: "white",
    fontSize: "0.8rem",
    fontFamily: "'Inter', sans-serif",
    display: "flex",
    width: "70%",
    maxWidth: "350px",
    borderRadius: "60px",
    padding: "4px 0",
    gap: "10px",
    border: "3px solid white",
    animation: "3s infinite pop",
    fontWeight: 600,
    transitionProperty: "transform",
    transitionDuration: " 0.2s",
    "&:hover": {
        background: "#ffffff00",
        transform: "scale(1.1)",
    },
}));

const CloseButton = styled(CloseIcon)((props) => ({
    position: 'absolute',
    right: "0",
    top: "0",
    margin: "20px",
    width: "30px",
    height: "30px",
    color: "#7F7F7F",
    cursor: "pointer",
    border: "2px solid #7F7F7F",
    padding: "3px",
    borderRadius: "100px",
    transitionProperty: "transform",
    transitionDuration: " 0.2s",
    '&:hover': {
        transform: "scale(1.1)",
    },
    zIndex:"2000"
}));



export { CloseButton, PrimaryButton, SecondaryButton }

