
const priceToUsdString = (price) => {
    // pre-checks
    const final = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: "usd",
    }).format(Number(price))
    return final
}

export { priceToUsdString }