
import React, { useState, useContext } from "react";
import { Box, Typography, Snackbar, Icon, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { PrimaryButton } from "../Buttons/Buttons";
import { AppContext } from "../../AppContext";

function AppFooter() {
    const { productData, setProductData } = useContext(AppContext)
    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const addProduct = () => {
        const currentProducts = [...productData?.checkoutProducts]
        const product =  productData?.displayedProduct?.accessoryProduct || productData?.displayedProduct?.mainProduct
        console.log("Product Trying to add from Add To Cart", product)
        if (product) {
            const findIndex = productData?.checkoutProducts.findIndex(prdt => prdt.id === product.id)
            if (findIndex > -1) {
                currentProducts[findIndex].quantity += 1
            } else {
                product.quantity = 1
                currentProducts.push(product)
            }
            setProductData((prev) => {
                return {
                    ...prev,
                    checkoutProducts: currentProducts
                }
            })
            setSnackbarOpen(true)
        }
    }

    return (
        <>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={snackbarOpen}
                onClose={() => { setSnackbarOpen(false); }}
                autoHideDuration={4000}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        backgroundColor: "black",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",  
                        width: "250px",
                    },
                }}
                message={
                    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center', width: "250px" }}>
                        <Icon sx={{ color: "#95783B", mr: 1 }}>
                            <CheckCircleIcon />
                        </Icon>
                        <Typography sx={{ fontSize: "0.8rem", fontWeight: 500 }}>Item successfully added to cart!</Typography>
                        {/* close X */}
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => { setSnackbarOpen(false); }}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                }
                
            />
            <Box
                sx={{
                    // position: "fixed",
                    padding: { xs: "20px 0", sm: "10px 0", },
                    bottom: "0",
                    left: "0",
                    zIndex: "400",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    margin: { xs: "0", sm: "-28px 0px 0px 0px" },
                    gap: "10px",
                    width: "100%",
                    backgroundImage: { xs: "linear-gradient(to top, #181B1D,#181B1D, #ffffff00)", sm: "none" },
                }}
            >

                <PrimaryButton onClick={addProduct}>Add to cart</PrimaryButton>

            </Box>
        </>

    );
}

export default AppFooter;



