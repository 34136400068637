import React, { createContext, useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import App from "./components/App";
import { _productData } from "./productData.js"
import { Box, Typography } from "@mui/material";
import { PrimaryButton } from "./components/Buttons/Buttons";
import ErrorIcon from '@mui/icons-material/Error';

export const AppContext = createContext()

const AppProvider = ({ children }) => {
    const [initalized, setInitalized] = useState(false);
    //  View State Contexts
    const [loading, setLoading] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
    const [detailOpen, setDetailOpen] = useState(0);
    const [sideMenu, setSideMenu] = useState(false);
    const [privacyMode, setPrivacyMode] = useState(false);
    const modeRef = useRef("tutorial");

    const getMode = () => modeRef.current;
    const setMode = (newMode) => {
        modeRef.current = newMode;
        forceUpdate(); // Force a re-render if needed
    };

    const [, setRender] = React.useState(0);
    const forceUpdate = () => setRender((prev) => prev + 1);

    const [storePolicy, setStorePolicy] = useState(null);

    //  Product Contexts
    const [productData, setProductData] = useState({
        allProducts: [],
        defaultProduct: null,
        displayedProduct: {
            mainProduct: null,
            accessoryProduct: null,
        },
        checkoutProducts: JSON.parse(localStorage.getItem("checkoutProducts")) || [],
        modelProducts: [],
        accessoryProducts: [],
    });
    const [isCheckingOut, setIsCheckingOut] = useState(false);
    // ThreeJS Contexts
    const [threeJSModule, setThreeJSModule] = useState(null);
    const [previewModalImage, setPreviewModalImage] = useState(false);
    const [arSupported, setArSupported] = useState(true);

    //  Error Contexts
    const [appDataError, setAppDataError] = useState(false);

    // Variables
    const recommendedItemsRef = useRef();
    const stripeAPI = process.env.REACT_APP_BACKEND_URL;
    const { name } = useParams();

    useEffect(() => {
        // Fetch Products 
        const fetchProductData = async () => {
            try {
                const response = await fetch(`${stripeAPI}/api/v1/products`);
                if (!response.ok) {
                    setAppDataError(true)
                }
                
                const data = await response.json();
                console.log({ data })
                let allProducts = [];
                let mainProducts = [];
                let accessoryProducts = [];

                data.data.forEach(product => {
                    allProducts.push(product);
                    if (product.category_name === "Throne") {
                        if (product?.id === 2) {
                            product.hotSpots = _productData.cart.hotSpots
                        } else if (product?.id === 3) {
                            product.hotSpots = _productData.caddy.hotSpots
                        }
                        mainProducts.push(product);
                    } else {
                        accessoryProducts.push(product);
                    }
                });
                
                if (name) {
                    const product = allProducts.find(product => {
                        return product.url === name
                    })
                    if (product) {
                        if (product.category_name === "Throne") {
                            product.hotSpots = _productData.cart.hotSpots
                        } else if (product.category_name === "Caddy") {
                            product.hotSpots = _productData.caddy.hotSpots
                        }

                        if (product.category_name === "Throne") {
                            setProductData({
                                allProducts,
                                mainProducts: mainProducts,
                                accessoryProducts: accessoryProducts,
                                defaultProduct: mainProducts[0],
                                displayedProduct: {
                                    mainProduct: product,
                                    accessoryProduct: null,
                                },
                                checkoutProducts: JSON.parse(localStorage.getItem("checkoutProducts")) || [],
                            });
                        } else {
                            setProductData({
                                allProducts,
                                mainProducts: mainProducts,
                                accessoryProducts: accessoryProducts,
                                defaultProduct: mainProducts[0],
                                displayedProduct: {
                                    mainProduct: mainProducts[0],
                                    accessoryProduct: product,
                                },
                                checkoutProducts: JSON.parse(localStorage.getItem("checkoutProducts")) || [],
                            });
                        }                        
                    } else {
                        setAppDataError(true)
                    }                    
                } else {
                    setProductData({
                        allProducts,
                        mainProducts: mainProducts,
                        accessoryProducts: accessoryProducts,
                        defaultProduct: mainProducts[0],
                        displayedProduct: {
                            mainProduct: mainProducts[0],
                            accessoryProduct: null,
                        },
                        checkoutProducts: JSON.parse(localStorage.getItem("checkoutProducts")) || [],
                    });
                }
            } catch (error) {
                setAppDataError(true)
                console.log('There was an error', error)
            }
        }

        // Fetch Store Policy
        const fetchStorePolicy = async () => {
            try {
                const response = await fetch(`${stripeAPI}/api/v1/store-policies`);
                if (!response.ok) {
                    throw new Error('Failed to fetch store policy');
                }
                const data = await response.json();
                setStorePolicy({
                    WebsitePrivacyPolicy: data.data.find(policy => policy.policy_name === "Website Privacy Policy"),
                    TermAndConditions: data.data.find(policy => policy.policy_name === "Standard Terms and Conditions"),
                    TermOfUse: data.data.find(policy => policy.policy_name === "Terms of Use"),
                });
            } catch (error) {
                
                console.log({ error });
            }
        }

        fetchProductData();
        fetchStorePolicy();
    }, [stripeAPI, name]);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setIsMobile(window.innerWidth < 900);
        });
    }, []);

    useEffect(() => {
        localStorage.setItem("checkoutProducts", JSON.stringify(productData?.checkoutProducts));
        localStorage.setItem("defaultProduct", JSON.stringify(productData?.defaultProduct));
    }, [productData?.checkoutProducts, productData?.defaultProduct]);

    return (
        <AppContext.Provider value={{
            loading,
            setLoading,
            isMobile,
            setIsMobile,
            modeRef,
            forceUpdate,
            getMode,
            setMode,
            detailOpen,
            setDetailOpen,
            sideMenu,
            setSideMenu,
            privacyMode,
            setPrivacyMode,
            storePolicy,
            setStorePolicy,
            productData,
            setProductData,
            isCheckingOut,
            setIsCheckingOut,
            threeJSModule,
            setThreeJSModule,
            previewModalImage,
            setPreviewModalImage,
            arSupported,
            setArSupported,
            recommendedItemsRef,
            stripeAPI,
            appDataError,
        }}>
            {productData?.allProducts.length > 0 && <App />}
            {appDataError && <NoPage />}
        </AppContext.Provider>
    );
};



export default AppProvider;

const NoPage = () => {
    // useEffect(() => {
    //     setInterval(() => {
    //         window.open(`${window.location.origin}`, "_self")
    //     }, 5000)

    //     return () => {
    //         clearInterval()
    //     }
    // })
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100%",
                gap: "20px"
            }}
        >
            <Typography variant="h4" sx={{ fontWeight: "600", color: "white" }}>404 Page Not Found</Typography>
            <ErrorIcon sx={{ fontSize: "100px", color: "white" }} />
            <PrimaryButton onClick={() => {window.open(`${window.location.origin}`, "_self")}}>Go to Home</PrimaryButton>
        </Box>
    )
};