
import React, { useState, useContext } from "react";
import { Box, Button, IconButton, Divider, styled, Drawer } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon
import { AppContext } from "../../AppContext";
import SideModal from "./SideModal";
import Recommended from "../Recommended/Recommended";
import TabFooter from "../AppDetail/Tabs/TabFooter";
import { pushToDataLayer } from "../../utilities/GoogleAnalytics";
import { Link, useNavigate } from "react-router-dom";

function SideMenu(props) {
    const currentWidth = window.innerWidth
    const { sideMenu, setSideMenu, setIsCheckingOut } = useContext(AppContext)
    const [mode, setMode] = useState(null)
    const navigate = useNavigate();

    return (
        <>
            {mode && <SideModal mode={mode} setMode={setMode} />}

            <Drawer
                anchor="left"
                open={sideMenu}
                onClose={() => { setSideMenu(false) }}
                CloseIcon={<CloseIcon />}
                PaperProps={{
                    sx: {
                        color: "white",
                        background: "linear-gradient(180deg, #111111 0%, #181B1D 100%)",
                        alignItems: "flex-start",
                        overflow: "hidden",
                        overflowY: "scroll",
                        maxWidth: { xs: "80vw", sm: "481px"},
                        overflowX: "hidden",
                        scrollbarWidth: "none",
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '&-ms-overflow-style:': {
                            display: 'none',
                        },
                        '&::after': {
                            content: '""',
                            position: 'fixed',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            pointerEvents: 'none',
                        },
                        '&::-webkit-overflow-scrolling': 'touch',
                    }
                }}
            >

                <Box
                    sx={{
                        position: 'relative',
                        top: "0",
                        left: "0",
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: 'center',
                        gap: "5px",
                        zIndex: '1400',
                        margin: "20px",
                        minWidth: { xs: "100%", sm: "481px" }
                    }}
                >
                    <IconButton
                        onClick={() => { setSideMenu(false); }}
                        sx={{
                            position: 'absolute',
                            top: '5px',
                            right: '25px',
                            zIndex: '1500',
                            color: 'white',
                        }}
                    >   
                        <CloseIcon />
                    </IconButton>


                    <TextButton
                        onClick={() => {
                            setIsCheckingOut(true)
                            pushToDataLayer({ event: "btn_clicked", clicked_button: "product_page" });

                        }}
                    >
                        purchase
                    </TextButton>
                    <TextButton
                        onClick={() => {
                            setMode("register")
                            pushToDataLayer({ event: "btn_clicked", clicked_button: "register_tool" });

                        }}
                    >
                        Register Tool
                    </TextButton>
                    <TextButton
                        onClick={() => {
                            setMode("register")
                            pushToDataLayer({ event: "btn_clicked", clicked_button: "register_tool" });

                        }}
                    >
                        Become a Retailer
                    </TextButton>
                    <TextButton
                        onClick={() => {
                            navigate("/products?category=Throne");
                            setSideMenu(false);
                            pushToDataLayer({ event: "btn_clicked", clicked_button: "view_all" });
                        }}
                    >
                        View all Throne
                    </TextButton>
                    <TextButton
                        onClick={() => {
                            setMode("video")
                            pushToDataLayer({ event: "btn_clicked", clicked_button: "watch_tutorial" });
                        }}
                    >
                        Watch tutorial
                    </TextButton>
                    <TextButton
                        onClick={() => {
                            props.starAR()
                            pushToDataLayer({ event: "btn_clicked", clicked_button: "start_ar" });
                        }}
                    >
                        Live Demo
                    </TextButton>

                </Box>
                <Divider sx={{
                    width: "70%",
                    backgroundColor: "#95783B",
                }} light />
                <Box

                    sx={{
                        top: "0",
                        left: "0",
                        display: 'flex',
                        flexDirection: "row",
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: "10px",
                        zIndex: '1400',
                        margin: "20px",

                    }}
                >

                    <CustomIconButton

                        onClick={() => {
                            setMode("email")
                            pushToDataLayer({ event: "btn_clicked", clicked_button: "email" });

                        }}
                    >
                        <MailOutlineIcon />
                    </CustomIconButton>

                    <CustomIconButton
                        onClick={() => {
                            setMode("phone")
                            pushToDataLayer({ event: "btn_clicked", clicked_button: "phone" });

                        }}
                    >
                        <PhonePausedIcon />
                    </CustomIconButton>

                    <CustomIconButton
                        onClick={() => {
                            setMode("linkedin")
                            pushToDataLayer({ event: "btn_clicked", clicked_button: "linkedin" });

                        }}
                    >
                        <LinkedInIcon />
                    </CustomIconButton>


                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        width: "100%",
                        minHeight: "200px",
                        padding: "20px",
                        display: 'flex',
                        flexDirection: "column",
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        gap: "10px",
                        zIndex: '1400',        
                    }}
                >
                    <Recommended onClose={() => { setSideMenu(false) }} isSideMenu={true} width={currentWidth} />
                </Box>
                <TabFooter
                    sx={{
                        alignItems: "flex-start",
                        marginLeft: "20px",
                        marginTop: "20px",
                    }}
                />
            </Drawer>

        </>
    );
}

export default SideMenu;


const TextButton = styled(Button)((props) => ({
    color: "white",
    padding: "5px",
    fontSize: "1.5rem",
    fontWeight: "600",
    transitionProperty: "transform",
    transitionDuration: " 0.2s",
    textAlign: "start"
}));



const CustomIconButton = styled(IconButton)((props) => ({
    color: "white",
    padding: "5px",
    backgroundColor: "#95783B",
    "&:hover": {
        background: "#95783B",
        transform: "scale(1.1)",
    },
    "& svg": {
        width: "15px",
        height: "15px",
    },
    transitionProperty: "transform",
    transitionDuration: " 0.2s",
}));