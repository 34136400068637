import { PlaneGeometry, MeshBasicMaterial, TextureLoader, Spherical, Object3D, Mesh } from 'three';

export default class HandHint extends Object3D {
    constructor(camera) {
        super()
        this.camera = camera
        this.loader = new TextureLoader();
        this.hand = this.loader.load("/assets/images/hand-hint.svg",)
        this.animationFrame = null

        this.material = new MeshBasicMaterial({ map: this.hand, transparent: true, depthTest: false, depthWrite: false });

        const geometry = new PlaneGeometry(0.01, 0.01)
        this.plane = new Mesh(geometry, this.material);
        this.plane.renderOrder = 10
        this.plane.position.z = -0.1
        // this.buildMesure()
        this.spherical = new Spherical().setFromVector3
        this.init()
    }


    init(size) {
        this.camera.add(this.plane)
        this.animate()
    }

    update(time) {
        const position = Math.sin(time / 500) * 0.005
        this.plane.position.x = position
        const offset = position * 200
        this.material.opacity = offset
        if (offset) {
            this.camera.position.x += position
        }
    }

    animate(time) {
        this.animationFrame = requestAnimationFrame(this.animate.bind(this));
        this.update(time)
        // this.arScene.translateX(0.02)
    }
    stop() {
        cancelAnimationFrame(this.animationFrame)
        this.camera.remove(this.plane)

    }
}
