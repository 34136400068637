
import React from 'react';
import { Box, styled } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const CustomLoading = styled(LinearProgress)(() => ({
    height: 10,
    width: "70%",
    maxWidth: "250px",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#EDEDED",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#B38F60',
    },
}));

function LoadingComp(props) {

    return (
        <>
            <Box sx={{
                position: 'absolute',
                top: '0',
                left: '0',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "40px",
                transition: 'opacity 1s',
                opacity: '1',
                zIndex: '1000',
                backgroundColor: "#111111b0",
                backdropFilter:"blur(1px)",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                fontWeight: 600,
            }}>
                <Box
                    component='img'
                    src={"/assets/images/the-throne-logo.svg"}
                    sx={{
                        fontSize: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                </Box>
                <CustomLoading variant="determinate" value={props.loading} />
            </Box>
        </>
    );
}

export default LoadingComp;


