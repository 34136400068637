
import React, { useState, createContext, useContext, useRef, useEffect } from "react";
import { Box, Typography, Modal, Snackbar, Alert, CircularProgress } from '@mui/material';
import { AppContext } from "../../AppContext";
import CheckoutModalHeader from "./Comps/CheckoutModalHeader";
import CheckoutTotalPrice from "./Comps/CheckoutTotalPrice";
import CheckoutProducts from "./Comps/CheckoutProducts";
import OtherProducts from "./Comps/OtherProducts";
import CheckoutProductModal from "./Comps/ProductCheckout";
import ProductCheckout from "./Comps/ProductCheckout";
import { PrimaryButton } from "../Buttons/Buttons";
import { pushToDataLayer } from "../../utilities/GoogleAnalytics";
import { getTotalPrice } from "./Comps/getTotalPrice";

export const CheckoutContext = createContext()

function CheckoutModal(props) {
    const [isChecking, setIsChecking] = useState(false)
    const [errorOpen, setErrorOpen] = useState(false)

    const { productData, setProductData, stripeAPI, isCheckingOut } = useContext(AppContext)
    const [previewProduct, setPreviewProduct] = useState(null)
    
    const changeQuantityHandler = (value, index) => {
        const qty = productData?.checkoutProducts[index].quantity + value
        console.log("Qty", qty)
        if (qty < 1) {
            removeProductHandler(index)
        } else {
            const newProducts = [...productData?.checkoutProducts]
            newProducts[index].quantity = qty
            setProductData((prev) => {
                return {
                    ...prev,
                    checkoutProducts: newProducts
                }
            });
        }
    }

    const addProductHandler = (product) => {
        if (productData?.checkoutProducts.find(cp => cp.name === product.name)) {
            const newProducts = productData?.checkoutProducts.map(cp => {
                if (cp.name === product.name) {
                    cp.quantity += 1
                }
                return cp
            })
            setProductData((prev) => {
                return {
                    ...prev,
                    checkoutProducts: newProducts
                }
            });
            return
        } else {
            // setCheckoutProducts([...checkoutProducts, { ...product, quantity: 1 }])
            setProductData((prev) => {
                return {
                    ...prev,
                    checkoutProducts: [...prev.checkoutProducts, { ...product, quantity: 1 }]
                }
            });
        }
    }

    const removeProductHandler = (index) => {
        const newArry = productData?.checkoutProducts.filter((word, id) => id !== index);
        setProductData((prev) => {
            return {
                ...prev,
                checkoutProducts: newArry
            }
        });
    }

    const checkoutHandler = () => {
        setIsChecking(true)
        pushToDataLayer({ event: "btn_clicked", clicked_button: "checkout" });
        const checkoutItem = []
        productData?.checkoutProducts.map(product => {
            const item = {
                "product_id": product?.id,
                "quantity": product?.quantity
            }
            checkoutItem.push(item)
        });
        console.log("Checkout Items", { checkoutItem })
        fetch(`${stripeAPI}/api/v1/payments`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ "line_items": checkoutItem,  }),
        })
            .then(res => {
                if (res.ok) return res.json()
                return res.json().then(json => Promise.reject(json))
            })
            .then(({ redirect_url }) => {
                window.location = redirect_url
            })
            .catch(e => {
                console.log("Error", e)
                console.error(e.error)
                setErrorOpen(true)
                setIsChecking(false)
            })
    }



    const myref = useRef(null);

    useEffect(() => {
        const scrollContainer = myref.current;
        if (scrollContainer) {
            const handleScroll = (e) => {
                e.preventDefault();
                if (scrollContainer) {
                    scrollContainer.scrollTop += e.deltaY * 0.5;
                }
            };
    
            scrollContainer.addEventListener('wheel', handleScroll);
    
            return () => {
                scrollContainer.removeEventListener('wheel', handleScroll); // Corrected to 'wheel'
            };
        }
    }, []);
    

    return (
        <CheckoutContext.Provider value={{
            checkoutProducts: productData?.checkoutProducts,
            allProducts: productData?.allProducts,
            setProductData,            
            changeQuantityHandler,
            addProductHandler,
            previewProduct,
            setPreviewProduct
        }}>
            <Box
                sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    maxWidth: "100vw",
                    height: "100vh",
                    background: "#fff",
                    margin: "0 auto",
                    width: "100vw",
                    zIndex: isCheckingOut ? 2000 : -1,
                }}
            >
                <Modal
                    open={isCheckingOut}
                disableAutoFocus={true}
                // onClose={() => { setIsCheckingout(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 2001,
                    background: "#282828ED",
                    // backgroundColor: "#282828ED",
                }}
            >
                <Box
                    sx={{
                        padding: "40px",
                        maxWidth: "550px",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "40px",
                        "&:focus": {
                            border: "none"
                        },
                        overflowY: "scroll",
                        scrollbarWidth: "none", // Hide the scrollbar for firefox
                        '&::-webkit-scrollbar': {
                            display: 'none', // Hide the scrollbar for WebKit browsers (Chrome, Safari, Edge, etc.)
                        },
                        '&-ms-overflow-style:': {
                            display: 'none', // Hide the scrollbar for IE
                        },

                    }}

                    ref={myref}
                >

                    <CheckoutModalHeader />
                    <CheckoutTotalPrice />
                    <CheckoutProducts />
                    <Typography>Add to your order</Typography>
                    <OtherProducts />

                    <PrimaryButton
                        disabled={isChecking}
                        onClick={checkoutHandler}
                    >{isChecking ? <CircularProgress size={20} sx={{ color: "white" }} /> : `Checkout ${getTotalPrice(productData?.checkoutProducts)}`}</PrimaryButton>
                </Box>
            </Modal>
            </Box>

            {previewProduct && <ProductCheckout onAddProduct={addProductHandler} />}

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={errorOpen}
                onClose={() => { setErrorOpen(false) }}
                autoHideDuration={2000}
            >
                <Alert
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Checkout Error!
                </Alert>
            </Snackbar>
        </CheckoutContext.Provider>
    );
}

export default CheckoutModal;



