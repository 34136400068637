import { Box, Typography, Modal } from '@mui/material';
import React from 'react';
import { useContext } from 'react';
import { CloseButton } from '../Buttons/Buttons';
import { AppContext } from '../../AppContext';
import PrivacyPolicy from '../../pages/PrivacyPolicy';
import TermAndConditions from '../../pages/TermAndConditions';
import TermOfUse from '../../pages/TermOfUse';

const PreviewPrivacyModal = () => {
    const { privacyMode, setPrivacyMode, storePolicy } = useContext(AppContext);
    const policyMap = {
        "privacy-policy": storePolicy?.WebsitePrivacyPolicy?.policy_name,
        "term-and-conditions": storePolicy?.TermAndConditions?.policy_name,
        "term-of-use": storePolicy?.TermOfUse?.policy_name
    };

    return (
        <Modal
            open={true}
            disableAutoFocus={true}
            onClose={() => { setPrivacyMode(null) }}
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box

                sx={{
                    width: "80%",
                    height: "80%",
                    backgroundColor: "white",
                    borderRadius: "25px",
                    padding: "20px",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                    overflow:"hidden"
                }}
            >
                <CloseButton onClick={() => { setPrivacyMode(null) }} />
                <Typography
                    sx={{
                        fontSize: "1.6rem",
                        fontWeight: "600",
                        marginTop: "20px",
                        textTransform: "capitalize"
                    }}
                >
                    {policyMap[privacyMode]}
                </Typography>
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",                        
                    }}
                >
                    {
                        privacyMode === "privacy-policy" && storePolicy &&
                        <PrivacyPolicy storePolicy={storePolicy} />
                    }
                    {
                        privacyMode === "term-and-conditions" && storePolicy &&
                        <TermAndConditions storePolicy={storePolicy} />
                    }
                    {
                        privacyMode === "term-of-use" && storePolicy &&
                        <TermOfUse storePolicy={storePolicy} />
                    }
                </Box>
            </Box>
        </Modal>
    );
};
export default PreviewPrivacyModal;

