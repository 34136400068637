import React, { useEffect, useRef, useContext } from "react";
import { Box } from "@mui/material";
import SideMenu from "./SideMenu/SideMenu";
import CheckoutModal from "./Checkout/CheckoutModal";
import ARErrorModal from "./ErrorHandle/ARErrorModal";
import { AppContext } from "../AppContext";
import { pushToDataLayer, initializeGTM } from "../utilities/GoogleAnalytics"
import Layout from "./Layout";
import MoreInfo from "./MoreInfo/MoreInfo";

function App() {
  const { threeJSModule, privacyMode, setIsCheckingOut, arSupported, setArSupported, productData } = useContext(AppContext);
  // usdz AR-QUICK-LOOK
  const linkRef = useRef();
  const location = window.location.toString();
  const htmlButtonLink = new URL(
    "/assets/ios-button/ios-shop-button-throne.html",
    location
  ).href;
  const usdzLink = `${productData?.displayedProduct?.mainProduct?.animation_file}#custom=${htmlButtonLink}&customHeight=medium`;
  // console.log({ appData })
  // console.log({ glbFile: appData.model_file })
  // console.log({ usdzLink })

  const getArSupported = async () => {
    try {
      if (navigator?.xr) {
        const arSupported = await navigator?.xr?.isSessionSupported('immersive-ar')
        return arSupported
      } else {
        console.log("WebXR is not available in this browser.")
        return false
      }
    } catch (error) {
      return false
    }

  }

  const startARhandler = async () => {
    const isWebXRSupported = await getArSupported();
    // linkRef.current.click();
    const IS_IOS = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !Window.MSStream) ||
      (/ipad|Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints > 1) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    // (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    const IS_WKWEBVIEW = Boolean(window.webkit && window.webkit.messageHandlers);

    const IS_AR_QUICKLOOK_CANDIDATE = (() => {
      if (IS_IOS) {
        if (!IS_WKWEBVIEW) {
          const tempAnchor = document.createElement('a');
          return Boolean(tempAnchor.relList && tempAnchor.relList.supports && tempAnchor.relList.supports('ar'));
        }
        else {
          return Boolean(/CriOS\/|EdgiOS\/|FxiOS\/|GSA\/|DuckDuckGo\//.test(navigator.userAgent));
        }
      }
      else {
        return false;
      }
    })();

    if (isWebXRSupported) {
      threeJSModule.enterAR()
    } else if (IS_AR_QUICKLOOK_CANDIDATE) {
      linkRef.current.click();
    } else {
      setArSupported(false)
    }
    pushToDataLayer({ event: "btn_clicked", clicked_button: "start_ar" });
  }

  useEffect(() => {
    initializeGTM();
    linkRef.current.addEventListener(
      "message",
      function (event) {
        setIsCheckingOut(true);
      },
      false
    );
  }, [setIsCheckingOut]);


  return (
    <>
      {productData?.allProducts && <CheckoutModal />}
      {!arSupported && <ARErrorModal onClose={() => { setArSupported(true) }} />}
      {privacyMode && <MoreInfo />}
      <SideMenu starAR={startARhandler} />
      <Layout startARhandler={startARhandler} />
      <Box sx={{ display: "none" }} alt="Box Model" rel="ar" component="a" ref={linkRef} href={usdzLink}>
        <img />
      </Box>
    </>
  );
}

export default App;
