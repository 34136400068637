const _productData = {
    cart: {
        name: "Throne Utility Cart",
        price: 0,
        file_name_3d: "black",
        previe_image: "./assets/images/black.png",
        id: "1",
        tabs: {
            details: {
                title: "About this product",
                content: "Throne Tools Cart has been designed to become your irreplaceable personal assistant Cart from packing in your tools at the start of your shift to keeping you in a comfortable position all day long. Throne Tools Cart can be customized for your specific needs with its proprietary attachments and keyhole designed into the frame—Throne Tools Cart when used correctly can make your job much safer and enjoyable. Usages and Applications: This Throne Tools Cart has been designed for professional workers, with the versatility to help around the home. "
            },
            specs: {
                model: "Black Cart",
                weight: "21.7kg",
                width: "56cm",
                height: "96cm",
                depth: "74cm"
            },
            gallery: {
                images: [
                    "./assets/images/IMG_0826.jpg",
                    "./assets/images/Rectangle 51color.png",
                    "./assets/images/Rectangle 53color.png",
                    "./assets/images/Rectangle 54.png",
                    "./assets/images/Rectangle 54color.png",
                ]
            },
            video: {
                src: "./assets/videos/the-throne.mp4",

            },
            faqs: {
                questions: [
                    {
                        question: "Key Features",
                        answer: "Throne Tool’s Cart Transforms into many different positions, allowing its user to work comfortably all day long, in many different positions, from working close to the ground to a stand-up desk, with shelf attachments*. A side cart is built into the Cart’s frame, for extra material or to kneel on. Proprietary keyholes designed throughout the frame, allow each Cart to be customized for each user, for many different tasks and endless attachment opportunities. Throne Tool Cart is compact and mobile, it rolls around just like your favorite carry-on luggage."
                    },
                    {
                        question: "Benefits",
                        answer: "Comfortable, Transformable, Mobile, increased productivity, Reduces Fatigue,  Increased Quality of Work, Tools and parts are always close by. Customizable with attachments, the Throne Tools Cart was designed to perform any task it is used for in the safest possible way. "
                    },
                    {
                        question: "Safety Considerations: ",
                        answer: "Throne Tool’s Cart makes many tasks much safer, the Cart’s ergonomics allow its users to adjust their position, for the safest position possible for each task at hand. Reduce fatigue, and add support in static positions. Please use the Throne Tool’s Cart safely. Any tool or product can lead to injury when not used as intended. Be conscious of your surroundings, use auxiliaries and parts as intended, and be familiar with all the Throne Tools functions. Review your personal safety plan before use and identify any hazards in your specific location. There are moving parts to this tool, that could pinch, roll over, or tip over, if not used correctly or loaded improperly.  "
                    }
                ]
            }
        },
        hotSpots: [
            {
                position: [
                    -0.18029728630111708,
                    0.5526500978662847,
                    -0.2207344111144085
                ],
                normal:
                    [
                        0,
                        1,
                        0
                    ],
                annotation: "Cart has built in quick attachment for caddy"
            },
            {
                position: [
                    0.2733278672945321,
                    0.22425073359091013,
                    -0.01027396377625174
                ],
                normal:
                    [
                        1,
                        0,
                        0
                    ],
                annotation: "Adjustable cushions for safety and comfort."
            },
            {
                position: [
                    -0.19852457455244107,
                    0.16993985592501434,
                    -0.08505479556565657
                ],
                normal:
                    [
                        -1,
                        0,
                        0
                    ],
                annotation: "All day comfort with tools and extra supplies always within reach"
            },
            {
                position: [
                    0.06812511886371109,
                    0.43587652755771766,
                    -0.16773070572249438
                ],
                normal:
                    [
                        0,
                        0,
                        1
                    ],
                annotation: "   Endless attachment opportunities for every industry"
            },
            {
                position: [
                    -0.10609932029614365,
                    0.15380055037274004,
                    0.04272166779914273
                ],
                normal:
                    [
                        0,
                        0,
                        1
                    ],
                annotation: "Endless attachment opportunities for every industry"
            }
        ]
    },
    caddy: {
        name: "Throne Caddy",
        price: 0,
        file_name_3d: "caddy",
        previe_image: "./assets/images/caddy.png",
        id: "2",
        tabs: {
            details: {
                title: "About this product",
                content: "Throne Tools Cart has been designed to become your irreplaceable personal assistant Cart from packing in your tools at the start of your shift to keeping you in a comfortable position all day long. Throne Tools Cart can be customized for your specific needs with its proprietary attachments and keyhole designed into the frame—Throne Tools Cart when used correctly can make your job much safer and enjoyable.     Usages and Applications: This Throne Tools Cart has been designed for professional workers, with the versatility to help around the home. "
            },
            specs: {
                model: "Black Cart",
                weight: "18.6kg",
                width: "56cm",
                height: "96cm",
                depth: "74cm"
            },
            gallery: {
                images: [
                    "./assets/images/IMG_0826.jpg",
                    "./assets/images/Rectangle 51color.png",
                    "./assets/images/Rectangle 53color.png",
                    "./assets/images/Rectangle 54.png",
                    "./assets/images/Rectangle 54color.png",
                ]
            },
            video: {
                src: "./assets/videos/the-throne.mp4",

            },
            faqs: {
                questions: [
                    {
                        question: "Key Features",

                        answer: "Throne Tool’s Cart Transforms into many different positions, allowing its user to work comfortably all day long, in many different positions, from working close to the ground to a stand-up desk, with shelf attachments*. A side cart is built into the Cart’s frame, for extra material or to kneel on. Proprietary keyholes designed throughout the frame, allow each Cart to be customized for each user, for many different tasks and endless attachment opportunities. Throne Tool Cart is compact and mobile, it rolls around just like your favorite carry-on luggage."
                    },
                    {
                        question: "Benefits",
                        answer: "Comfortable, Transformable, Mobile, increased productivity, Reduces Fatigue,  Increased Quality of Work, Tools and parts are always close by. Customizable with attachments, the Throne Tools Cart was designed to perform any task it is used for in the safest possible way. "
                    },
                    {
                        question: "Safety Considerations: ",
                        answer: "Throne Tool’s Cart makes many tasks much safer, the Cart’s ergonomics allow its users to adjust their position, for the safest position possible for each task at hand. Reduce fatigue, and add support in static positions. Please use the Throne Tool’s Cart safely. Any tool or product can lead to injury when not used as intended. Be conscious of your surroundings, use auxiliaries and parts as intended, and be familiar with all the Throne Tools functions. Review your personal safety plan before use and identify any hazards in your specific location. There are moving parts to this tool, that could pinch, roll over, or tip over, if not used correctly or loaded improperly.  "
                    }
                ]
            }
        },
        hotSpots: [
            {
                position: [
                    -0.18029728630111708,
                    0.5526500978662847,
                    -0.2207344111144085
                ],
                normal:
                    [
                        0,
                        1,
                        0
                    ],
                annotation: "Cart has built in quick attachment for caddy"
            },
            {
                position: [
                    0.2733278672945321,
                    0.22425073359091013,
                    -0.01027396377625174
                ],
                normal:
                    [
                        1,
                        0,
                        0
                    ],
                annotation: "Adjustable cushions for safety and comfort."
            },
            {
                position: [
                    -0.19852457455244107,
                    0.16993985592501434,
                    -0.08505479556565657
                ],
                normal:
                    [
                        -1,
                        0,
                        0
                    ],
                annotation: "All day comfort with tools and extra supplies always within reach"
            },
            {
                position: [
                    0.06812511886371109,
                    0.43587652755771766,
                    -0.16773070572249438
                ],
                normal:
                    [
                        0,
                        0,
                        1
                    ],
                annotation: "   Endless attachment opportunities for every industry"
            },
            {
                position: [
                    -0.10609932029614365,
                    0.15380055037274004,
                    0.04272166779914273
                ],
                normal:
                    [
                        0,
                        0,
                        1
                    ],
                annotation: "Endless attachment opportunities for every industry"
            }
        ]
    },

}

export { _productData }