import { PlaneGeometry, Group, CircleGeometry, ShapeGeometry, Object3D, MeshBasicMaterial, Mesh } from 'three';
import { FontLoader } from 'three/addons/loaders/FontLoader.js';


export default class Mesure extends Object3D {
    constructor() {
        super()
        this.loader = new FontLoader();
        this.font = null
        this.loader.load("/assets/font/helvetiker_regular.typeface.json", (font) => {
            this.font = font
        })
        this.width = null
        this.depth = null
        this.height = null
    }

    loadFont(url) {
        return new Promise((resolve, reject) => {
            try {
                this.loader.load(url, (font) => {
                    resolve(font);
                })
            } catch (error) {
                reject(error);
            }
        })
    }

    build(size) {

        if (size) {
            if (!this.width || !this.height || !this.depth) {
                this.width = new MesureSingle(this.font, size.width)
                this.height = new MesureSingle(this.font, size.height)
                this.depth = new MesureSingle(this.font, size.depth)

                this.add(this.width)
                this.add(this.height)
                this.add(this.depth)

                this.updateSize(size)
            } else {
                this.updateSize(size)
            }

        }

    }

    updateSize(size) {
        this.width.position.z = size.depth / 2 + 0.1
        this.width.position.y = - size.height / 2
        this.width.rotation.x = - Math.PI / 2

        this.height.position.x = size.width / 2
        this.height.position.z = -size.depth / 2 - 0.1
        this.height.rotation.z = - Math.PI / 2

        this.depth.position.x = -size.width / 2 - 0.1
        this.depth.rotation.x = - Math.PI / 2
        this.depth.position.y = - size.height / 2
        this.depth.rotation.z = Math.PI / 2
    }
}




class MesureSingle extends Object3D {
    constructor(font, length) {
        super()
        const geometry = new CircleGeometry(0.02, 3).translate(-0.02, 0, 0);
        const boxgeometry = new PlaneGeometry(0.004, 0.2).translate(-0.002, 0, 0);
        const linegeometry = new PlaneGeometry(1, 0.004).translate(-0.5, 0, 0);
        const material = new MeshBasicMaterial({ color: 0xffffff });

        this.arrow = new Mesh(geometry, material)
        this.edge = new Mesh(boxgeometry, material)
        this.line = new Mesh(linegeometry, material)
        this.line.scale.x = length / 2 - 0.2
        this.left = new Group();

        this.left.add(this.arrow)
        this.left.add(this.edge)
        this.left.add(this.line)

        this.right = this.left.clone()
        this.right.rotation.z = Math.PI
        this.left.position.x = length / 2
        this.right.position.x = -length / 2


        const mToFeet = Number((length * 3.28084).toFixed(2))

        const shapes = font.generateShapes(`${mToFeet}ft`, 0.06);

        const textGeometry = new ShapeGeometry(shapes);
        textGeometry.computeBoundingBox();
        const xMid = - 0.5 * (textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x);
        const yMid = - 0.5 * (textGeometry.boundingBox.max.y - textGeometry.boundingBox.min.y);

        textGeometry.translate(xMid, yMid, 0);

        this.text = new Mesh(textGeometry, material);
        this.add(this.left)
        this.add(this.right)
        this.add(this.text)
    }

}