
import React, { useContext } from "react";
import { Box, Modal } from '@mui/material';
import { AppContext } from "../../AppContext";
function ImagePreviewModal() {
    const { previewModalImage, setPreviewModalImage } = useContext(AppContext)
    return (
        <Modal
            open={true}
            disableAutoFocus={true}
            onClose={() => { setPreviewModalImage(null) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    backgroundColor: "white",
                    padding: "40px"
                }}
            >
                <Box
                    component='img'
                    src={previewModalImage}
                    sx={{
                        width: "300px"
                    }}
                >
                </Box>
            </Box>
        </Modal>
    );
}

export default ImagePreviewModal;



