
import { Box } from "@mui/material";

function TermAndConditions(props) {
  const termAndConditions = props?.storePolicy?.TermAndConditions;
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      >
      <Box
        dangerouslySetInnerHTML={{ __html: termAndConditions?.policy_text }}
        sx={{
          width: "95%",
          height: "100%",
          padding: "0px 20px 90px 20px",
          textAlign: "justify",
          overflow: "auto",
          
        }}
      />
    </Box>
  );
}

export default TermAndConditions;


