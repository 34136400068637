const getTotalPrice = (checkoutProducts) => {
    let total = 0
    if (checkoutProducts?.length > 0) {
        checkoutProducts.map(product => {
            const unitCost = product?.price_details?.retail_price * product.quantity
            total += unitCost
        })
    }
    const price = Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: checkoutProducts && checkoutProducts[0]?.price_details?.currency || "USD",
    }).format(total)
    return price

}

export { getTotalPrice }