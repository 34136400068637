import React, { useContext } from 'react';
import PrivacyModal from './PrivacyModal';
import { AppContext } from '../../AppContext';
import PreviewPrivacyModal from './PreviewPrivacyModal';

const MoreInfo = () => {
    const { privacyMode, setPrivacyMode, } = useContext(AppContext);
    return (
        <>
            {privacyMode === 'brand' &&
                <PrivacyModal onClose={() => { setPrivacyMode(null) }} />
            }
            {privacyMode === 'privacy-policy' &&
                <PreviewPrivacyModal />
            }
            {privacyMode === 'term-and-conditions' &&
                <PreviewPrivacyModal />
            }

            {privacyMode === 'term-of-use' &&
                <PreviewPrivacyModal />
            }
        </>

    );
};
export default MoreInfo;


