import { Box, Typography, Grow, styled, Modal, Button } from '@mui/material';
import React from 'react';
import logoFile from './assets/Logo.svg';

const PrivacyModal = (props) => {

    const redireTo = (to) => {
        window.open(to, '_blank')
    }
    return (
        <>
            <Modal
                open={true}
                disableAutoFocus={true}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Grow
                    in={true}
                >
                    <Box
                        sx={{
                            width: "95%",
                            maxWidth: "328px",
                            backgroundColor: "white",
                            borderRadius: "25px",
                            flexDirection: "column",
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "20px",
                            padding: "24px",
                            position: 'relative',
                        }}
                    >
                        <Box
                            component='img'
                            src={props.logo ? props.logo : logoFile}
                            sx={{
                                position: 'absolute',
                                top: "-50px",
                                width: "80px",
                                borderRadius: "10px",
                                padding: "4px",
                            }}
                        />
                        {props.company ?
                            <Typography
                                sx={{
                                    color: " #000",
                                    textAlign: "center",
                                    fontFamily: "Outfit",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "normal",
                                    maxWidth: "300px",
                                    marginTop: "30px",
                                }}
                            >
                                {props.company}
                            </Typography> :
                            <Typography
                                sx={{
                                    color: " #000",
                                    textAlign: "center",
                                    fontFamily: "Outfit",
                                    fontSize: "20px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "normal",
                                    maxWidth: "300px",
                                    marginTop: "30px",
                                }}
                            >
                                3Mera
                            </Typography>
                        }

                        {props.content ?
                            <Typography
                                sx={{
                                    color: " #000",
                                    textAlign: "center",
                                    fontFamily: "Outfit",
                                    fontSize: "15px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                    maxWidth: "300px",
                                }}
                            >
                                {props.content}
                            </Typography> :
                            <Typography
                                sx={{
                                    color: " #000",
                                    textAlign: "center",
                                    fontFamily: "Outfit",
                                    fontSize: "15px",
                                    fontStyle: "normal",
                                    fontWeight: "400",
                                    lineHeight: "normal",
                                    maxWidth: "300px",
                                }}
                            >
                                By proceeding, you agree to 3Mera’s Terms of Service and acknowledge the Privacy Policy.
                            </Typography>
                        }
                        <XrGoodButton
                            onClick={() => {
                                redireTo("https://xrgoods.co/privacy-policy")
                            }}
                            sx={{
                                backgroundColor: "#2753B0"
                            }}
                        >Privacy policy</XrGoodButton>
                        <XrGoodButton
                            onClick={() => {
                                redireTo("https://feedback.xrgoods.co/bug-finder")
                            }}
                        >report bug</XrGoodButton>
                        <XrGoodButton
                            onClick={props.onClose}
                            sx={{
                                backgroundColor: "#75B53C",
                                marginTop: "80px",
                                '&:hover': {
                                    backgroundColor: "#75B53C",
                                },
                            }}
                        >Confirm</XrGoodButton>
                    </Box>
                </Grow>
            </Modal>

        </>
    );
};
export default PrivacyModal;

const XrGoodButton = styled(Button)((props) => ({
    height: "45px",
    color: "white",
    'fontSize': '0.8rem',
    fontFamily: "'Inter', sans-serif",
    'display': 'flex',
    'width': '100%',
    maxWidth: "350px",
    'borderRadius': '60px',
    'padding': '4px 0',
    'gap': '10px',
    'animation': '3s infinite pop',
    backgroundColor: "#2753B0",
    cursor: "pointer",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
    fontWeight: 600,
    transitionProperty: "transform",
    transitionDuration: " 0.2s",
    '&:hover': {
        backgroundColor: "#2753B0",
        transform: "scale(1.1)",
    },
}));