
import React, { useContext, useState } from "react";
import { Box } from '@mui/material';
import { AppContext } from "../../AppContext";
import ProductTitle from "./ProductTitle";
import Tabs from "./Tabs/Tabs";
import ImagePreviewModal from "../PreviewModal/ImagePreviewModal";


function AppDetailCard({ offsetStart, setOffsetStart, offsetTop, setOffsetTop }) {
    const { detailOpen, previewModalImage, setDetailOpen } = useContext(AppContext)
    const setDetailOpenHandler = () => {

        if (detailOpen === 0) {
            if (offsetTop < 0) {
                setDetailOpen(1)
            } else {
                setDetailOpen(-1)
            }
        } else if (detailOpen === 1) {

            if (offsetTop > 0) {
                setDetailOpen(0)
            }
        } else {
            if (offsetTop < 0) {
                setDetailOpen(0)
            }
        }
    }

    const onClickHandler = () => {
        const _open = detailOpen > 0 ? 0 : 1
        setDetailOpen(_open)
    }

    return (
        <>
            <Box
                sx={{
                    top: "0",
                    left: "0",
                    zIndex: "1000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                    backgroundColor: "#181B1D",
                    // backgroundColor: "#181b1d5e",
                    borderRadius: "20px"
                }}
            >
                <OpenButton onClick={onClickHandler} setDetailOpenHandler={setDetailOpenHandler} setDetailOpen={setDetailOpen} offsetStart={offsetStart} offsetTop={offsetTop} setOffsetStart={setOffsetStart} setOffsetTop={setOffsetTop} />
                <ProductTitle />
                <Tabs isAsscessorieModal={false} />
            </Box>
            {previewModalImage && <ImagePreviewModal />}
        </>
    );
}

export default AppDetailCard;


const OpenButton = ({ onClick, setDetailOpenHandler, offsetStart, offsetTop, setOffsetStart, setOffsetTop }) => {
    const [lastTouch, setLastTouch] = useState(undefined)

    return (
        <Box

            onTouchStart={(e) => {
                setOffsetStart(e.touches[0].pageY)
                setLastTouch(undefined)
            }}
            onTouchMove={(e) => {
                const offsetY = offsetStart - e.touches[0].pageY
                setLastTouch(e.touches[0].pageY)
                setOffsetTop(-offsetY)
            }}
            onTouchEnd={(e) => {

                if (lastTouch) {
                    setDetailOpenHandler()
                }
                setOffsetTop(0)
                setLastTouch(undefined)
            }}

            onClick={onClick}
            sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20px 0",
                cursor: "pointer"
            }}
        >
            <Box
                sx={{
                    height: "4px",
                    width: "50px",
                    backgroundColor: "white",
                    borderRadius: "10px"
                }}
            >
            </Box>
        </Box>

    )
}
