
import { Box,Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { pushToDataLayer } from "../../utilities/GoogleAnalytics";
function VideoModal({showVideo,setShowVideo}) {
    return (
        <Modal
            open={showVideo}
            disableAutoFocus={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box sx={{
                position: 'absolute',
                top: '0',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#282828e0',
                transition: 'opacity 1s',
                opacity: '1',
                zIndex: '2700',
                gap: "40px",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                fontWeight: 600,
            }}>
                <Box
                    component='video'
                    autoPlay
                    controls
                    muted
                    src="/assets/videos/the-throne.mp4"
                    sx={{
                        width: "75%",
                        maxWidth: "350px",
                    }}
                >
                </Box>

                <CloseIcon
                    onClick={() => {
                        setShowVideo(false)
                        pushToDataLayer({ 'event': 'btn_clicked', 'clicked_button': 'watch_tutorial_closed' })
                    }}
                    sx={{
                        position: 'absolute',
                        right: "0",
                        top: "0",
                        margin: "20px",
                        width: "30px",
                        height: "30px",
                        color: "#7F7F7F",
                        cursor: "pointer",
                        border: "2px solid #7F7F7F",
                        padding: "3px",
                        borderRadius: "100px",
                        transitionProperty: "transform",
                        transitionDuration: " 0.2s",
                        '&:hover': {
                            transform: "scale(1.1)",
                        },
                    }}

                ></CloseIcon>
            </Box>
        </Modal>
    );
}

export default VideoModal;


