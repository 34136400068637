
import { useState, useContext, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import AppDetail from './AppDetail/AppDetail';
import AppMode from './AppMode/AppMode';
import AppHeader from './AppHeader/AppHeader';
import ModelViewer_3Mera from './ModelViewer/ModelViewer_3Mera';
import { AppContext } from "../AppContext";
import AppFooter from './AppFooter/AppFooter';
import Recommended from './Recommended/Recommended';
import AppDetailCard from './AppDetail/AppDetailCard';
import AccessoriesPopup from './Accessories/Accessories';
import ProductPage from './Products/ProductPage';

function Layout({ startARhandler }) {
    const isProductPage = window.location.pathname.includes("/products") && !window.location.pathname.includes("/products/");
    const { detailOpen, setDetailOpen, productData } = useContext(AppContext);
    const [offsetTop, setOffsetTop] = useState(0)
    const [offsetStart, setOffsetStart] = useState(0)
    // const miniHeight = 

    const [miniHeight, setMiniHeight] = useState(window.innerHeight * 0.70)

    // const miniHeight = 500
    const getCardOffset = () => {
        if (detailOpen === 1) {
            return 70
        }
        if (detailOpen === -1) {
            const height = window.innerHeight
            return height - 155
        }
        return miniHeight
    }
    const getOffset = () => {

        const cardOffset = getCardOffset()
        if (offsetTop) {
            let _offsetTop = cardOffset + offsetTop
            if (_offsetTop < 60) {
                _offsetTop = 60
            }
            return `${_offsetTop}px`
        }
        return `${cardOffset}px`

    }

    useEffect(() => {
        let portrait = window.matchMedia("(orientation: portrait)");
        const setMiniHeightHandle = (e) => {
            // if (e.matches) {
            //     // Portrait mode
            //     console.log("Portrait")
            // } else {
            //     // Landscape
            //     console.log("Landscape")
            // }
            setDetailOpen(0)
            setMiniHeight(window.innerHeight * 0.70)

        }
        portrait.addEventListener("change", setMiniHeightHandle)
        return () => {
            portrait.removeEventListener("change", setMiniHeightHandle)
        }
    }, [setDetailOpen])

    const memorizedModelViewer = useMemo(() => {
        return <ModelViewer_3Mera starAR={startARhandler} />
    }, [startARhandler])

    return (
        <Box
            sx={{
                position: "absolute",
                top: "0",
                height: "100%",
                width: "100%",
                background: "linear-gradient(180deg, #33393E 0%, #181B1D 100%)",
                display: "flex",
                flexDirection: "column",
                alignItems: 'center',
                justifyContent: "flex-start",

            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: 'center',
                    justifyContent: "flex-start",
                    maxWidth: "1500px",
                    width: '100%',
                    overflow: "hidden",
                }}
            >

                <Box
                    sx={{
                        width: "100%",
                        position: "absolute",
                        top: "0",
                        zIndex: "1000",
                    }}
                >
                    <AppHeader />
                </Box>
                {isProductPage && <ProductPage />}
                <Box
                    sx={{
                        width: "100%",
                        display: isProductPage ? "none" : "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: 'flex-start',
                        justifyContent: "flex-start",
                        gap: "20px",
                    }}

                >
                        <Box
                            sx={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: 'flex-start',
                                justifyContent: "flex-start",
                                width: "100%",
                            }}
                        >


                            <Box
                                sx={{
                                    width: "100%",
                                    height: getOffset(),
                                    transition: offsetTop ? "none" : "height 0.5s",
                                    minHeight: `${miniHeight}px`,
                                    position: "relative",

                                }}
                            >
                                <AppMode />
                                {productData?.displayedProduct?.mainProduct?.model_file && productData?.displayedProduct?.mainProduct?.animation_file &&
                                    memorizedModelViewer
                                }
                                {productData?.displayedProduct?.accessoryProduct &&
                                    <AccessoriesPopup product={productData?.displayedProduct?.accessoryProduct} />
                                }

                            </Box>
                            <Box
                                sx={{
                                    display: { xs: "none", sm: "block" },
                                    padding: "20px"
                                }}

                            >
                                <Recommended />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                maxWidth: "400px",
                                height: "100%",
                                padding: { xs: "0", sm: "20px 20px 20px 0px" },
                            }}
                        >

                            <Box
                                sx={{
                                    top: getOffset(),
                                    transition: offsetTop ? "none" : "top 0.5s",
                                    position: "absolute",
                                    width: "100%",
                                    zIndex: "1100",
                                    display: { xs: "block", sm: "none" }
                                }}
                            >
                                <AppDetailCard offsetStart={offsetStart} setOffsetStart={setOffsetStart} offsetTop={offsetTop} setOffsetTop={setOffsetTop} />

                            </Box>
                            <Box
                                sx={{
                                    display: { xs: "none", sm: "block" },
                                    width: "100%",
                                    marginTop: "10px",
                                    position: 'relative',
                                }}
                            >
                                <AppDetail offsetStart={offsetStart} setOffsetStart={setOffsetStart} offsetTop={offsetTop} setOffsetTop={setOffsetTop} />
                            </Box>
                        </Box>
                </Box>


                <Box

                    sx={{
                        position: "fixed",
                        bottom: "0",
                        width: "100%",
                        display: { xs: "flex", sm: "none" },
                        flexDirection: "row",
                        maxWidth: "1500px",
                        zIndex: "1200",
                        justifyContent: { xs: "center", sm: "space-between" },
                    }}
                >
                    <Box
                        sx={{
                            display: { xs: "none", sm: "block" }
                        }}

                    >
                    </Box>
                    <Box
                        sx={{
                            display: isProductPage ? "none" : "flex",
                            width: "100%",
                            maxWidth: { xs: "none", sm: "400px" },
                            flexDirection: "row",
                            justifyContent: "center",
                        }}
                    >

                        <AppFooter />
                    </Box>

                </Box>
            </Box>
        </Box >

    );
}

export default Layout;



