import React, { useRef, useContext, useEffect, useCallback } from "react";
import { Box, styled, Button, IconButton, Slider, SliderThumb } from '@mui/material';
import { AppContext } from "../../AppContext";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';

const CustomTitle = styled(Button)(({ active }) => ({
    color: active ? "white" : "#A6ACB8",
    writingMode: "tb-rl",
    transform: "rotate(-180deg)",
    fontWeight: active ? "700" : "400",
    fontSize: "14px",
    fontFamily: "Inter",
    textTransform: "capitalize",
    padding: "8px",
    minWidth: "20px",
    '@media (max-height: 500px)': {
        writingMode: "horizontal-tb",
        transform: "rotate(0deg)",
    },
}));

function AppMode(props) {
    const { modeRef, getMode, setMode, forceUpdate, threeJSModule, productData } = useContext(AppContext);
    const playRef = useRef(threeJSModule?.isPlaying());
    const animationTimeRef = useRef(0);

    const onClickHandler = (name) => {
        setMode(name);
        if (threeJSModule) {
            threeJSModule.updateMode(name);
        }
        if (name === "tutorial") {
            setPlay(false);
        }
    };

    const handlePartsInputChange = (event) => {
        if (!threeJSModule?.partsAnimationReady) {
            console.warn("Attempted to update animation time when not ready.");
            return;
        }
        const time = Number(event.target.value);
        threeJSModule.updateAnimationTime(time);
    };

    const handleAnimationTimeChange = (event) => {
        if (!threeJSModule?.partsAnimationReady) {
            console.warn("Attempted to update animation time when not ready.");
            return;
        }
        const time = Number(event.target.value);
        threeJSModule.updateTutorialAnimationTime(time);
        animationTimeRef.current = time;
            // playRef.current = false;
        forceUpdate();
    };

    const setPlay = (play) => {
        playRef.current = play;
        forceUpdate();
    };

    const handleAnimationPausePlay = () => {
        if (playRef.current) {
            threeJSModule.pauseAnimation(true);
        } else {
            threeJSModule.pauseAnimation(false);
        }
        setPlay(!playRef.current);
    };

    const fetchAnimationTime = useCallback(() => {
        if (threeJSModule?.partsAnimationReady && modeRef.current === "tutorial") {
            let time = threeJSModule.getCurrentAnimationTime();
            if (time !== 0) {
                animationTimeRef.current = time;
                forceUpdate();
            }
        }
    }, [threeJSModule]);

    const buttons = [
        { title: "Parts", mode: "parts" },
        { title: "Size", mode: "size" },
        { title: "Tutorial", mode: "tutorial" },
        { title: "Features", mode: "feature" },
    ];

    useEffect(() => {
        if (getMode() !== "tutorial") return;
        if (threeJSModule?.partsAnimationReady) {
            fetchAnimationTime();
            threeJSModule?.updateMode("tutorial");
            threeJSModule.pauseAnimation(true);
            setPlay(false);
        }
    }, [productData, threeJSModule?.partsAnimationReady, threeJSModule, fetchAnimationTime]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchAnimationTime();
        }, 100);

        return () => clearInterval(interval);
    }, [fetchAnimationTime]);

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    left: { xs: "0", md: "10px" },
                    zIndex: "100",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "2px",
                    '@media (max-height: 500px)': {
                        flexDirection: "row",
                        top: "0",
                        left: "50%",
                        transform: "translateX(-50%)",
                        height: "40px",
                        width: "100%",
                        backgroundImage: "none",
                        zIndex: "1000",
                    },
                }}
            >
                {buttons.map((button, index) => (
                    <CustomTitle key={index} active={getMode() === button.mode ? "true" : null} onClick={() => { onClickHandler(button.mode) }}>{button.title}</CustomTitle>
                ))}
            </Box>

            {getMode() === "parts" && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Slider
                        slots={{ thumb: AirbnbThumbComponent }}
                        sx={{
                            height: "50%",
                            zIndex: "200",
                            color: "white",
                            '& input[type="range"]': {
                                WebkitAppearance: 'slider-vertical',
                            },
                            '& .MuiSlider-thumb': {
                                height: 27,
                                width: 27,
                                backgroundColor: '#fff',
                                border: '1px solid "#000000"',
                                borderRadius: "5px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                '&:hover': {
                                    boxShadow: 'none',
                                },
                                '&:focus': {
                                    boxShadow: 'none',
                                },
                            },
                        }}
                        orientation="vertical"
                        defaultValue={0}
                        onChange={handlePartsInputChange}
                    />
                </Box>
            )}

            {getMode() === "tutorial" && (
                <Box
                    sx={{
                        display: `${threeJSModule?.partsAnimationReady ? "flex" : "none"}`,
                        position: "absolute",
                        bottom: "20px",
                        left: "50%",
                        transform: { xs: "translateX(-60%)", md: "translateX(-50%)" },
                        display: "flex",
                        width: "fit-content",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "20px",
                        zIndex: "1000",
                    }}
                >
                    <IconButton
                        sx={{
                            color: "white",
                            zIndex: "1000",
                            backgroundColor: "#4b4b4b99",
                            width: "32px",
                            height: "32px",
                        }}
                        onClick={handleAnimationPausePlay}
                    >
                        {playRef.current ? <PlayArrowIcon fontSize="24px" /> : <PauseOutlinedIcon fontSize="24px" />}
                    </IconButton>

                    <Slider
                        slots={{ thumb: HorizontalAirbnbThumbComponent }}
                        orientation="horizontal"
                        value={animationTimeRef.current}
                        onChange={handleAnimationTimeChange}
                        aria-labelledby="animation-slider"
                        min={0}
                        max={100}
                        sx={{
                            minWidth: { xs: "200px", sm: "300px" },
                            zIndex: "200",
                            color: "white",
                            '& input[type="range"]': {
                                WebkitAppearance: 'slider-vertical',
                            },
                            '& .MuiSlider-thumb': {
                                height: 27,
                                width: 27,
                                backgroundColor: '#fff',
                                border: '1px solid "#000000"',
                                borderRadius: "5px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                '&:hover': {
                                    boxShadow: 'none',
                                },
                                '&:focus': {
                                    boxShadow: 'none',
                                },
                            },
                        }}
                    />
                </Box>
            )}
        </>
    );
}

export default AppMode;

function AirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <Box
                sx={{
                    width: "10px",
                    height: "2px",
                    backgroundColor: "#000000"
                }}
            ></Box>
            <Box
                sx={{
                    width: "10px",
                    height: "2px",
                    backgroundColor: "#000000"
                }}
            ></Box>
            <Box
                sx={{
                    width: "10px",
                    height: "2px",
                    backgroundColor: "#000000"
                }}
            ></Box>
        </SliderThumb>
    );
}

function HorizontalAirbnbThumbComponent(props) {
    const { children, ...other } = props;
    return (
        <SliderThumb {...other}>
            {children}
            <Box
                sx={{
                    width: "10px",
                    height: "2px",
                    backgroundColor: "#000000"
                }}
            ></Box>
            <Box
                sx={{
                    width: "10px",
                    height: "2px",
                    backgroundColor: "#000000"
                }}
            ></Box
            ><Box
                sx={{
                    width: "10px",
                    height: "2px",
                    backgroundColor: "#000000"
                }}
            ></Box>
        </SliderThumb>
    );
}

