
import React, { useContext } from "react";
import { Box, Typography, } from '@mui/material';
import Recommended from "../../../Recommended/Recommended";
import TabFooter from "../TabFooter";
import { AppContext } from "../../../../AppContext";

const Detail = ({ detailData, isAsscessorieModal, setCheckoutProduct, handleClose }) => {
    const { setDetailOpen } = useContext(AppContext)
    return (
        <Box
            sx={{
                height: isAsscessorieModal ? "114%" : "100%",
                padding: { xs: "20px", sm: "10px 0" },
            }}
        >

            <Box
                sx={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography 
                    dangerouslySetInnerHTML={{ __html: detailData }}
                    sx={{
                        color: "white",
                        lineHeight: { xs: "1.2", sm: "1.2" },
                        fontSize: isAsscessorieModal ? "0.9rem" : { xs: "0.8rem", sm: "1rem" },
                        height: isAsscessorieModal ? "fit-content" : "auto",
                    }}
                />
            </Box>
            <Box
                sx={{
                    // display only on mobile unless isAsscessorieModal is true
                    display: { xs: "block", sm: isAsscessorieModal ? "block" : "none" },                  
                }}
            >
                <Recommended handleClose={handleClose} onClose={() => { setDetailOpen(false) }} isSideMenu={false} setCheckoutProduct={setCheckoutProduct} />
            </Box>
            {!isAsscessorieModal && <TabFooter /> }
            < Box />
        </Box>

    );
}
export default Detail;


