
import React, { useContext } from "react";
import { Box, IconButton, Typography} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AppContext } from "../../../AppContext";

function CheckoutModalHeader() {

    const { setIsCheckingOut } = useContext(AppContext);

    return (
        <Box
            sx={{
                top: "0",
                left: "0",
                zIndex: "1000",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                width: "100%",
            }}
        >
            <Box
                onClick={() => { setIsCheckingOut(false) }}
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "10px",
                    cursor:"pointer"
                }}
            >

                <IconButton

                    sx={{
                        color: "white"
                    }}
                >
                    <ArrowBackIcon />
                </IconButton>

                <Box
                    component="img"
                    src={"/assets/images/logo512.png"}
                    sx={{
                        width: "40px",
                        fontSize: "2rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                ></Box>
                <Typography>
                    Throne Tools Corporation
                </Typography>
            </Box>

        </Box>
    );
}

export default CheckoutModalHeader;


