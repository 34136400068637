
import React, { useContext } from "react";
import { Box } from '@mui/material';
import { AppContext } from "../../AppContext";
import AppFooter from "../AppFooter/AppFooter";
import ProductTitle from "./ProductTitle";
import Tabs from "./Tabs/Tabs";
import ImagePreviewModal from "../PreviewModal/ImagePreviewModal";

function AppDetail() {
    const { previeModalImage } = useContext(AppContext)

    return (
        <>
            <Box
                sx={{
                    top: "0",
                    left: "0",
                    zIndex: "1000",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: "10px",
                    width: "100%",
                    borderRadius: "20px",
                    marginTop: "80px"
                }}
            >
                <ProductTitle />
                <Box
                    sx={{
                        display: { xs: "none", sm: "flex" },
                        width: "100%",
                        maxWidth: { xs: "none", sm: "400px" },
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >

                    <AppFooter />
                </Box>
                <Tabs isAsscessorieModal={false} />
            </Box>
            {previeModalImage && <ImagePreviewModal />}
        </>
    );
}
export default AppDetail;
