
import React, { useContext } from "react";
import { Box } from '@mui/material';
import { AppContext } from "../../../../AppContext";

const Gallery = ({ previewProduct }) => {

    const { setPreviewModalImage } = useContext(AppContext)

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
            }}
        >
            <Box
                onClick={() => { setPreviewModalImage(previewProduct.default_image) }}
                sx={{
                    width: { xs: "200px", sm: "324px" },
                    height: { xs: "200px", sm: "324px" },
                    backgroundImage: `url(${previewProduct.default_image})`,
                    backgroundSize: "cover"
                }}
            />


        </Box>

    );
}


export default Gallery;


